import {Layout} from 'antd';
import React, {useEffect, useState} from 'react';
import './index.less'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {EnumAgreement} from "@/utils/enum";
import logo from '@/assets/logo.png'

const {Footer} = Layout;


export default function FooterBar() {
    const navigate = useNavigate();
    const [isWhite, setIsWhite] = useState(false);
    const location = useLocation();
    console.log(location.pathname)

    useEffect(()=>{
        if ('/info'===location.pathname){
            setIsWhite(true);
        }else{
            setIsWhite(false);
        }
    })

    return (
        <Layout>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: isWhite?"#FFF":"#F6F6F6"
                }}
            >
                <div className="footer-heart">
                    <div className="top">
                        <div className="column1">
                            <div className="brand"><img width="100%" src={logo} alt=""/></div>
                        </div>
                        <div className="column2">
                            <div className="title" onClick={()=>{  navigate("/")}}>首页</div>
                            <div className="every"><Link to="/" >推荐餐厅</Link></div>
                        </div>
                        <div className="column2">
                            <div className="title">预订</div>
                            <div className="every"><Link to="/canteen">餐厅列表</Link></div>
                            <div className="every"><Link to="/register">新用户注册</Link></div>
                            <div className="every"><Link to="/info">预约订单</Link></div>
                        </div>
                        <div className="column2">
                            <div className="title" onClick={()=>{  window.open("/userAgreement?typ="+EnumAgreement.shiyong, '_blank')}}>使用条款</div>
                            <div className="every"  onClick={()=>{  window.open("/userAgreement?typ="+EnumAgreement.fuwu, '_blank')}}>服务协议</div>
                            <div className="every"  onClick={()=>{  window.open("/userAgreement?typ="+EnumAgreement.yinsi, '_blank')}}>隐私协议</div>
                            <div className="every"  onClick={()=>{  window.open("/userAgreement?typ="+EnumAgreement.guanyu, '_blank')}}>关于我们</div>
                        </div>
                    </div>
                    <div className="bottom">
                        ICP备：京ICP备20021275号
                    </div>
                </div>
            </Footer>
        </Layout>)
}