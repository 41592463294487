import React, {useEffect, useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Form, message, Upload} from 'antd';
import {getUser, request, setUser} from "@/utils";
import Config from "@/config/config";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


const AliyunOSSUploadImg = ({value, onChange,imgUrl,type}) => {
    const [loading, setLoading] = useState(false);
    const [OSSData, setOSSData] = useState();
    const [imageUrl, setImageUrl] = useState();

    const getSTSData = async () => {
        try {
            const response = await request.get('tool/sts-token?type=image',);
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                const tmpObj=response.data
                let storage="";
                if(type){
                    storage=type;
                }
                const data={

                    "accessId":tmpObj.access_key_id,
                    "host": Config.imgPrefix,
                    "policy": tmpObj.policy,
                    "signature": tmpObj.signature,
                    "securityToken": tmpObj.security_token,
                    "dir": "reservation/"+storage+"/",
                };
                return data;
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }


    // Mock get OSS api
    // https://help.aliyun.com/document_detail/31988.html
    const mockGetOSSData = () => ({
        // dir: 'user-dir/',
        // expire: '1577811661',
        // host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
        // accessId: 'c2hhb2RhaG9uZw==',
        // policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
        // signature: 'ZGFob25nc2hhbw==',

    });
    const init = async () => {
        try {
            const result = await getSTSData();
            setOSSData(result);
        } catch (error) {
            message.error(error);
        }

    };
    useEffect(() => {
        init();
    }, []);

    // const handleChange = (info) => {
    //     if (info.file.status === 'uploading') {
    //         setLoading(true);
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj, (url) => {
    //             setLoading(false);
    //             setImageUrl(url);
    //         });
    //     }
    // };

    const handleChange = ({fileList}) => {
        console.log('Aliyun OSS:', fileList);
        onChange?.([...fileList]);

        if (fileList[0]?.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (fileList[0]?.status === 'done') {
            // Get this url from response in real world.
            getBase64(fileList[0]?.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };
    const onRemove = (file) => {
        const files = (value || []).filter((v) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };
    const getExtraData = (file) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
        "x-oss-security-token":OSSData.securityToken,

    });
    const beforeUpload = async (file) => {

        if (!OSSData) return false;
        const expire = Number(OSSData.expire) * 1000;
        if (expire < Date.now()) {
            await init();
        }
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        // @ts-ignore
        file.url = OSSData.dir + filename;
        return file;
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    // const beforeUpload = (file) => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //         message.error('You can only upload JPG/PNG file!');
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //         message.error('Image must smaller than 2MB!');
    //     }
    //     return isJpgOrPng && isLt2M;
    // };

    const uploadProps = {
        name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,

    };

    const uploadButton = (
        <button
            style={{
                width:"452px",
                height:"240px",
                // border: "1px dashed #999999",
                border: "1px dashed  #ccc",
                background: 'none',
                backgroundColor:"rgba(0,0,0,0.02)",
                color:"#999999",


            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                点击上传用餐凭证
            </div>
        </button>
    );

    return (
        <Upload showUploadList={false} {...uploadProps}>
            {imgUrl ? <img src={Config.imgPrefix+imgUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    );
};
export default AliyunOSSUploadImg
