import React, {useState, useEffect,useRef,useCallback} from "react";
import "./index.less";
import {Pagination} from "antd";
import {useNavigate} from "react-router-dom";
import {request} from "@/utils/request"
import axios from "axios";
import NavigationBar from "@/components/NavigationBar";
import Config from "@/config/config";

export default function Canteen() {
    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [cityList, setCityList] = useState([]);
    const [sectList, setSectList] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedSects, setSelectedSects] = useState([]);
    const [recommendList, setRecommendList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [total, setTotal] = useState(0)
    const listRef = useRef(null);
    const handleCityClick = (city) => {
        let sectIdList=[];
        let cityIdList=[];
        if (city===null){
            setSelectedCities([]);
        }else{
            const isCity = selectedCities.findIndex((item) => item.id === city.id);
            if (isCity !== -1) {
                const newSelectedCities = selectedCities.filter(
                    (item) => item.id !== city.id
                );
                setSelectedCities(newSelectedCities);
                newSelectedCities.map((item)=>{
                    cityIdList.push(item.id)
                })
            } else {
                let arr = [...selectedCities];
                arr.push(city);
                setSelectedCities(arr);
                arr.map((item)=>{
                    cityIdList.push(item.id)
                })
            }
        }

        selectedSects.map((item)=>{
            sectIdList.push(item.id)
        })

        fetchData(0, perPage, '',sectIdList,cityIdList)
    };

    const handleSectClick = (type) => {
        let sectIdList=[];
        let cityIdList=[];
        if (type===null){
            setSelectedSects([]);
        }else{
            const isType = selectedSects.findIndex((item) => item.id === type.id);
            if (isType !== -1) {
                const newSelectedSects = selectedSects.filter(
                    (item) => item.id !== type.id
                );
                setSelectedSects(newSelectedSects);
                newSelectedSects.map((item)=>{
                    sectIdList.push(item.id)
                })
            } else {
                let arr = [...selectedSects];
                arr.push(type);
                setSelectedSects(arr);
                arr.map((item)=>{
                    sectIdList.push(item.id)
                })
            }

        }

        selectedCities.map((item)=>{
            cityIdList.push(item.id)
        })

        fetchData(0, perPage, '',sectIdList,cityIdList)
    };
    const fetchData = async (page, perPage, name = '', sect_id = [], city_id = []) => {
        try {
            let url = 'store/list' + `?page=${page}&per-page=${perPage}`;
            if (name) {
                url += "&name=" + name
            }
            sect_id.map((item)=>{
                url += "&sect_id=" + item
            })
            city_id.map((item)=>{
                url += "&city_id=" + item
            })
            const response = await request.get(url);
            setRecommendList(response.data);
            setTotal(response._meta.totalCount)

        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(()=>{
        // listRef.current.scrollIntoView({ behavior: "smooth" })
        if(listRef.current){
            window.scrollTo(0,0)
        }
    },[recommendList])

    const getCityList = async () => {
        setCityList((await request.get('city/list?per-page=1000')).data);
    }
    const getSectList = async () => {
        setSectList((await request.get('sect/list?per-page=1000')).data);
    }
    const pageChange = (e, v) => {

        setPage(e)
        setPerPage(v)
        fetchData(e, v)
    };

    const doSearch = (val) => {
        // fetchData(0, perPage, val)
        console.log(val)
        // fetchData(0,perPage,val)
        navigate('/search?name='+val)
    }

    useEffect(() => {

        sessionStorage.setItem("mbx","canteen")
        getCityList();
        getSectList();
        fetchData(page, perPage);

    }, []);

    return (
        <div className="canteen" ref={listRef}>
            <NavigationBar showNavRight={true} showNavTabs={true} showSearch={true}
                           onSearchChange={doSearch}></NavigationBar>
            <div className="canteen-heart">
                <div className="select">
                    <div className="city">
                        <span className="city-head">城市</span>
                        <div className="city-name">

                                <div
                                    className={`city-item ${selectedCities.length===0?"selected1":""}`}
                                    onClick={() => handleCityClick(null)}
                                >
                                    全部选择
                                </div>


                                {cityList &&
                                    cityList.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={`city-item ${
                                                    selectedCities.includes(item) ? "selected1" : ""
                                                }`}
                                                onClick={() => handleCityClick(item)}
                                            >
                                                {item.val}
                                            </div>
                                        );
                                    })}


                        </div>
                    </div>
                    <div className="type">
                        <div className="type-head">
                            类型
                        </div>
                        <div className="type-name">
                            <div
                                className={`type-item ${selectedSects.length===0?"selected1":""}`}
                                onClick={() => handleSectClick(null)}
                            >
                                全部选择
                            </div>
                            {sectList &&
                                sectList.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className={`type-item ${
                                                selectedSects.includes(item) ? "selected1" : ""
                                            }`}
                                            onClick={() => handleSectClick(item)}
                                        >
                                            {item.val}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="type-rcmdlist" >
                    <div className="rcmdlist-title" >推荐餐厅</div>
                    <div className="rcmdlist-item" >
                        {recommendList &&
                            recommendList.map((item, idx) => {
                                return (
                                    <div
                                        className="box"
                                        key={idx}
                                        onClick={() => navigate('/detail?id=' + item.id)}
                                        style={{cursor:"pointer"}}
                                    >
                                        <div
                                            className="picture"

                                        >
                                            {item.prize_enable===1 && <div className="prize-icon">抽选</div>}
                                            <img src={Config.imgPrefix+item.cover} style={{borderRadius: "4px"}} width="100%"
                                                 height="100%" alt=""/>
                                        </div>
                                        <div className="box-right">
                                            <div className="name">{item.name}</div>
                                            <div className="city">
                                                <div className="city-left">{item.sect && item.sect.val}/{item.city && item.city.val}</div>
                                                <div className="city-right">
                                                    <i className="iconfont icon-kehudizhi"></i>
                                                    <span>{item.address_detail}</span>
                                                </div>
                                            </div>
                                            <div className="describe">{item.desc}</div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <Pagination
                        className='main-pagination'
                        total={total}
                        pageSize={perPage}
                        pageSizeOptions={[12, 24, 36, 48]}
                        onChange={pageChange}
                        showQuickJumper
                        showTotal={(total) => `共${total}条`}
                        current={page}
                    />
                </div>
            </div>
        </div>
    );
}
