import axios from "axios";
import {getToken, removeToken} from "@/utils";

// const baseUrl='http://localhost:8082/api/v1/'
// const baseUrl='https://jp-reservation.dev.seastart.cn/api/v1/'
const baseUrl="https://api.dineinjp.com/api/v1/"
const baseIframeUrl="https://api.dineinjp.com"

const request = axios.create({
    baseURL: baseUrl,
    timeout: 5000,
})
// 添加请求拦截器
request.interceptors.request.use((config) => {
    const token = getToken()
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use((response) => {
    // 2xx 范围内的状态码都会触发该函数。
    if(response.data.ret.code===10042){
        removeToken()
    }
    // 对响应数据做点什么
    return response.data
}, (error) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
})


export { request,baseUrl,baseIframeUrl }