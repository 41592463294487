const TokenKey = 'token' //

export function getToken() {
    return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
    return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return sessionStorage.removeItem(TokenKey)
}

export function setUser(info) {
    return sessionStorage.setItem('user', JSON.stringify(info))
}
export function getUser() {
    return JSON.parse(sessionStorage.getItem('user'))
}