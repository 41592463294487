import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom'
import router from './router'
import 'antd/dist/antd.less';
import { Provider } from 'react-redux';
import store from './store';
import 'normalize.css'
import zhCN from "antd/es/locale/zh_CN";
// import zhCN from 'antd/lib/locale/zh_CN'
import 'moment/dist/locale/zh-cn'
import { ConfigProvider } from "antd";
import '@/iconFont/iconfont.css'
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={zhCN}>
    {/*<React.StrictMode>*/}
      <Provider store={store}>
        <RouterProvider router={router}>
        </RouterProvider>
      </Provider>
    {/*</React.StrictMode>*/}
  </ConfigProvider>

);

