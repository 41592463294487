import React, {useState, useEffect} from 'react';
import './index.less'
import {request} from "@/utils";
import {Pagination, Image, Modal, Button, message, Select, Form} from "antd";
import {baseUrl} from "@/utils/request";
import {OrderEnum} from "@/utils/enum";
import {useNavigate} from "react-router-dom";
import SecondsDown from "@/components/SecondsDownComponent";
import {Func} from "@/utils/func";
import wxIcon from '@/assets/wx.png'
import aliIcon from '@/assets/ali.png'
import {queryByPlaceholderText} from "@testing-library/react";
import Config from '@/config/config'
import noSearch from "@/assets/noSearch.png";
import AliyunOSSUploadImg from "@/components/AliyunOssComponent/index2";

export default function OrderComponent(props) {

    const [imgUrl, setImgUrl] = useState("")
    const [orderList, setOrderList] = useState([])
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [total, setTotal] = useState(0)
    const navigate = useNavigate()
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isCancelModalOpenBeforePay, setIsCancelModalOpenBeforePay] = useState(false);
    const [cancelId, setCancelId] = useState(0);
    const [refundId, setRefundId] = useState(0);
    const [finishId, setFinishId] = useState(0);
    const [applyRefundDepositId, setApplyRefundDepositId] = useState(0);
    const [refundReason, setRefundReason] = useState('');
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [isRefundBeforeModalOpen, setIsRefundBeforeModalOpen] = useState(false);
    const [isOrderFinishModalOpen, setIsOrderFinishModalOpen] = useState(false);
    const [isOrderApplyRefundDepositModelOpen, setIsOrderApplyRefundDepositModelOpen] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [detail, setDetail] = useState({});
    const [payChooseWx, setPayChooseWx] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const [form] = Form.useForm();
    const fetchData = async (page, perPage) => {

        try {
            let param = "";
            if (props.orderType&& props.orderType !=="0") {
                param = "&sub_status=" + props.orderType
            }else{
                let orderType=""
                const menu=queryParams.get("menu")
                if (menu==="order-to-paid"){
                    orderType=OrderEnum.orderToPaid
                }
                if (menu==="order-to-confirm"){
                    orderType=OrderEnum.orderToConfirm
                }
                if (menu==="order-is-confirmed"){
                    orderType=OrderEnum.orderIsConfirmed
                }
                if (menu==="order-to-refund"){
                    orderType=OrderEnum.orderToRefund
                }
                if (menu==="order-meal-done"){
                    orderType=OrderEnum.orderMealDone
                }
                if (menu==="order-to-apply-deposit"){
                    orderType=OrderEnum.orderRefundDepositReceipt
                }
                if (menu==="order-finish"){
                    orderType=OrderEnum.orderFinish
                }

                if (orderType){
                    param = "&sub_status=" + orderType
                }
            }

            const response = await request.get('order/list' + `?page=${page}&per-page=${perPage}` + param); // 使用封装好的Axios实例发送GET请求
            setTotal(response._meta.totalCount)
            setOrderList(response.data)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const cancelOrder = (detail) => {
        if(detail.sub_status===OrderEnum.orderToPaid){
            setCancelId(detail.id)
            setIsCancelModalOpenBeforePay(true)
        }else{
            setCancelId(detail.id)
            setIsCancelModalOpen(true)
        }


    };

    const refundOrderBefore = (id) => {
        setRefundId(id)
        setIsRefundBeforeModalOpen(true)
    }

    const orderFinishModelOpen = (id) => {
        setFinishId(id)
        setIsOrderFinishModalOpen(true)
    }

    const confirmRefundBefore = () => {
        setIsRefundBeforeModalOpen(false)
        refundOrder(refundId)

    }
    const cancelRefundBefore = () => {
        setIsRefundBeforeModalOpen(false)
    }


    const refundOrder = (id) => {
        setRefundId(id)
        setIsRefundModalOpen(true)
    }

    const handleSelectRefundReason = (value) => {
        setRefundReason(value)
    }

    const confirmRefund = () => {
        //提交退款申请
        const applyRefund = async () => {
            const response = await request.post('order/apply-refund?id=' + refundId, {
                id: refundId,
                'remark': refundReason
            }); // 使用封装好的Axios实例发送GET请求
            setDetail(response.data)
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                setIsRefundModalOpen(false)
                message.success('申请退款成功');
                navigate("?menu=order-to-refund&id="+refundId);
                window.location.reload();
                // fetchData(page, perPage)
            }
        }
        applyRefund();


    }


    const cancelRefund = () => {
        setIsRefundModalOpen(false)
    }

    const confirmRefundDeposit = (values) => {
        console.log(values);
        if (!imgUrl){
            message.error("用餐凭证不能为空")
            return;
        }
        //提交退款申请
        const applyRefundDeposit = async () => {
            const response = await request.post('order/apply-refund-deposit-receipt', {
                id: applyRefundDepositId,
                info: imgUrl
            }); // 使用封装好的Axios实例发送GET请求
            setDetail(response.data)
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                setIsRefundModalOpen(false)
                message.success('申请退款成功');
                if (applyRefundDepositId == detail.id) {
                    detail.sub_status = OrderEnum.orderRefundDepositReceipt
                    setDetail({...detail})
                }
                // fetchData(page, perPage)
                navigate("?menu=order-to-apply-deposit&id="+applyRefundDepositId);
                window.location.reload();
            }
            setIsOrderApplyRefundDepositModelOpen(false)
        }
        applyRefundDeposit();


    }
    const cancelRefundDeposit = () => {
        form.resetFields();
        setImgUrl("");
        setIsOrderApplyRefundDepositModelOpen(false)
    }

    const refundOptions = function () {
        let arr = [];
        let list = [
            "行程取消",
            "更换了其他店铺",
            "变更预约信息",
            "其他",
        ];
        for (let i = 0; i < list.length; i++) {
            arr.push({
                value: list[i],
                label: list[i],
            })

        }

        return arr;
    }

    const PageChange = (e, v) => {

        setPage(e)
        setPerPage(v)
        fetchData(e, v)
    };

    const getDetail = async (id) => {
        try {
            const response = await request.get('order/detail?id=' + id); // 使用封装好的Axios实例发送GET请求
            setDetail(response.data)
            if (response.data.pay_time > 0) {
                handleClose();
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    const subDetail = (id) => {
        // item.sub_price_desc="预约金额描述"
        // item.cancel_rule='[{"key":2,"val":50},{"key":1,"val":100}]'
        // setDetail(item)
        getDetail(id)
        setIsShowDetail(true);
        let menu = queryParams.get("menu");
        navigate(`/info?menu=${menu}&id=` + id)
    }

    const backToList = () => {
        setIsShowDetail(false);
        fetchData(page, perPage)
    }

    const imgUploadResult=(v)=>{
        if(v[v.length-1]['status']==="done"){
            const imgUrl = v[v.length-1]['url'];
            const ossPrefix = Config.imgPrefix;
            setImgUrl(imgUrl);
            console.log(v);
        }
    }

    const getColorClassname = (item) => {

        switch (Number(item.sub_status)) {
            case OrderEnum.orderToPaid:
                return "order-to-paid";
            case OrderEnum.orderToConfirm:
                return "order-to-confirm";
            case OrderEnum.orderIsConfirmed:
                return "order-is-confirmed";
            case OrderEnum.orderToRefund:
                return "order-to-refund";
            case OrderEnum.orderCanceled:
                return "order-canceled";
            case OrderEnum.orderClose:
                return "order-canceled";
            case OrderEnum.orderFinish:
                return "order-canceled";
            case OrderEnum.orderRefundDepositReceipt:
                return "order-done";
            case OrderEnum.orderMealDone:
                return "order-done";
        }
        return "";
    }
    const getStatusName = (item) => {

        switch (Number(item.sub_status)) {
            case OrderEnum.orderToPaid:
                return "待付款";
            case OrderEnum.orderToConfirm:
                return "预约确认中";
            case OrderEnum.orderIsConfirmed:
                return "预约成功";
            case OrderEnum.orderToRefund:
                return "待退款";
            case OrderEnum.orderCanceled:
                return "已取消";
            case OrderEnum.orderClose:
                return "已关闭";
            case OrderEnum.orderFinish:
                return "已完成";
            case OrderEnum.orderMealDone:
                return "就餐完成";
            case OrderEnum.orderRefundDepositReceipt:
                return "申请中";
        }
        return "";
    }


    const [visible, setVisible] = useState(false);
    const [paySchema, setPaySchema] = useState('');
    const getPaySchema = async (item, type, openid = "") => {
        const response = await request.post('order/pay', {
            "sn": item.sn,
            "openid": openid,
            "platform": "web",
            "pay_type": type
        });

        if (response.ret.code) {
            message.error(response.ret.cn)
        } else {
            setPaySchema(response.data);
            let count = 1;

            function orderPayListen() {

                let run = sessionStorage.getItem('orderPayListen')
                if (!run || count > 600) {
                    return;
                }
                getDetail(item.id)
                console.log(run);
                count++;

                return setTimeout(orderPayListen, 5000)
            }

            if (!sessionStorage.getItem("orderPayListen")) {
                setTimeout(orderPayListen, 5000);
                sessionStorage.setItem("orderPayListen", "yes")
            }

        }
    }

    const handleOpen = (item) => {
        setVisible(true);
        console.log(item);
        getPaySchema(item, payChooseWx ? "wxpay" : "alipay");

    }

    const targetPayChooseWx = (isWx) => {
        setPayChooseWx(isWx)
        if (isWx) {
            getPaySchema(detail, "wxpay");
        } else {
            getPaySchema(detail, "alipay");
        }
    }

    const handleClose = () => {
        setVisible(false);
        sessionStorage.setItem("orderPayListen", "")
        fetchData(page, perPage)
    }

    const confirmCancel = async () => {
        //取消预约
        setIsCancelModalOpen(false)
        // const response = await request.post('order/cancel', {id: cancelId});
        // if (response.ret.code) {
        //     message.error(response.ret.cn)
        // } else {
        //     if (cancelId == detail.id) {
        //         detail.sub_status = OrderEnum.orderCanceled
        //         setDetail({...detail})
        //     }
        //     fetchData(page, perPage)
        // }
        // let data = response.data
    };

    const confirmCancelBeforePay = async () => {
        //取消预约
        setIsCancelModalOpenBeforePay(false)
        const response = await request.post('order/cancel', {id: cancelId});
        if (response.ret.code) {
            message.error(response.ret.cn)
        } else {
            if (cancelId == detail.id) {
                detail.sub_status = OrderEnum.orderCanceled
                setDetail({...detail})
            }
            fetchData(page, perPage)
        }
        let data = response.data
    };

    const iKnow = async () => {
        //取消预约
        setIsCancelModalOpen(false)
    };


    const cancelCancel = () => {
        setIsCancelModalOpen(false)

    }
    const orderApplyRefundDepositModelOpen =(id)=>{
        setApplyRefundDepositId(id)
        setIsOrderApplyRefundDepositModelOpen(true)
    }

    const cancelCancelBeforePay = () => {
        setIsCancelModalOpenBeforePay(false)

    }

    //就餐完成
    const confirmFinish = async () => {

        setIsOrderFinishModalOpen(false)
        const response = await request.post('order/meal-done', {id: finishId});
        if (response.ret.code) {
            message.error(response.ret.cn)
        } else {
            if (finishId == detail.id) {
                detail.sub_status = OrderEnum.orderMealDone
                setDetail({...detail})
            }
            // fetchData(page, perPage)
            navigate("?menu=order-meal-done&id="+finishId);
            window.location.reload();

        }
        let data = response.data
    };


    const cancelFinish = () => {
        setIsOrderFinishModalOpen(false)
    }

    useEffect(() => {
        console.log(props);
        setIsShowDetail(false)

        if (props.toDetail) {
            subDetail(props.toDetail)
        } else {
            fetchData(page, perPage);
        }


    }, [props.orderType, props.toDetail]);

    return (
        <>
            {isShowDetail === false && <div className="content-box">
                <div className="content-title">
                    {queryParams.get("menu") === 'order' && "所有预约"}
                    {queryParams.get("menu") === 'order-to-paid' && "待付款"}
                    {queryParams.get("menu") === 'order-to-confirm' && "预约中"}
                    {queryParams.get("menu") === 'order-is-confirmed' && "已预约"}
                    {queryParams.get("menu") === 'order-to-refund' && "待退款"}
                    {queryParams.get("menu") === 'order-meal-done' && "就餐完成"}
                    {queryParams.get("menu") === 'order-to-apply-deposit' && "申请中"}
                    {queryParams.get("menu") === 'order-finish' && "已完成"}
                </div>
                {orderList.length>0 && <div className="row-flex order-head list-title">
                    <div className="order-rest-head">餐厅名称</div>
                    <div className="order-item-head">预约人数</div>
                    <div className="order-item-head">合计</div>
                    <div className="order-item-head">预约状态</div>
                    <div className="order-item-head">交易操作</div>
                </div>}
                {orderList.length===0 &&
                    <>
                        <div width={900} height={600} style={{margin:"0 auto",paddingBottom:"286px",textAlign:"center"}}>
                            <img width={280} src={noSearch} alt=""/>
                            <div width={280} style={{textAlign:"center"}}><span style={{color:"#999999",fontSize:"14px"}}>暂无数据</span></div>
                        </div>

                    </>
                }
                {orderList.length>0 && <div className=" list-content">
                    {orderList && orderList.map((item) => {
                        return (
                            <>
                                <div className="list-content-item-box">


                                    <div className="order-content-item-head list-content-head">
                                        <span>
                                                   预约号: {item.sn}
                                        </span>
                                        {item.sub_status ===OrderEnum.orderRefundDepositReceipt&&<span style={{color:"#999999"}}>
                                            申请通过后套餐押金会原路退回
                                        </span>}
                                        {item.sub_status ===OrderEnum.orderFinish&&<span style={{color:"#999999"}}>
                                            套餐押金已退回
                                        </span>}

                                    </div>
                                    <div className="order-content-item-body list-content-body">

                                        {/*<div className="item-box">*/}
                                        <div className="item-head" >

                                            <div className="store-cover inline">
                                                <img width='100%'
                                                     src={Config.imgPrefix + item.store.cover}
                                                     alt=""
                                                />
                                            </div>
                                            <div className="inline">
                                                <div>
                                                    {item.store.name}
                                                </div>
                                                <div><span className="item-sub-time">预约时间: {item.sub_date.join(',')} {item.sub_time}</span></div>
                                            </div>

                                        </div>
                                        <div className="item-other">
                                            {item.sub_num}
                                        </div>
                                        <div className="item-other">
                                            <div>{item.amount}</div>
                                            <div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(含预约费用+套餐押金)</div>
                                        </div>
                                        <div className="item-other">
                                            <div className={getColorClassname(item)} style={{lineHeight:"32px"}}>{getStatusName(item)}</div>
                                            <div><Button style={{border: "none", boxShadow: "none"}}
                                                         onClick={() => subDetail(item.id)}>预约详情</Button></div>
                                        </div>
                                        <div className="item-other">
                                            {Func.isOrderActionShow(item, 'pay') &&
                                                <div><Button style={{border: "none", boxShadow: "none"}}
                                                             onClick={() => handleOpen(item)}>立即支付</Button></div>}
                                            {Func.isOrderActionShow(item, 'cancel') &&
                                                <div><Button style={{border: "none", boxShadow: "none"}}
                                                             onClick={() => cancelOrder(item)}>取消预约</Button></div>}
                                            {Func.isOrderActionShow(item, 'apply-refund') &&
                                                <div>
                                                    <Button style={{border: "none", boxShadow: "none"}}
                                                            onClick={() => orderFinishModelOpen(item.id)}>就餐完成</Button>
                                                    <Button style={{border: "none", boxShadow: "none"}}
                                                             onClick={() => refundOrderBefore(item.id)}>申请退款</Button>
                                                </div>}
                                            {
                                                Func.isOrderActionShow(item, 'apply-refund-deposit')
                                                // true
                                                &&
                                                <div>
                                                    <Button style={{border: "none", boxShadow: "none"}}
                                                            onClick={() => orderApplyRefundDepositModelOpen(item.id)}>申请退押金</Button>

                                                </div>}
                                        </div>
                                        {/*</div>*/}


                                    </div>
                                </div>
                            </>);
                    })}
                    <div className="pagination">
                        <Pagination
                            className="canteen-pagination"
                            total={total}
                            pageSize={perPage}
                            pageSizeOptions={[5, 10, 15, 20]}
                            onChange={PageChange}
                            showQuickJumper
                            showTotal={(total) => `共${total}条`}
                            current={page}
                        />
                    </div>


                </div>}
            </div>}
            {isShowDetail === true && detail && <div className="content-box">
                <div><Button style={{width: '74px', height: '32px',borderRadius:"4px",color:"#999999"}} onClick={() => backToList()}>返回</Button></div>

                {//待付款
                    detail.sub_status == OrderEnum.orderToPaid &&
                    <div className="between-slider">
                        <div>
                            <div className="detail-status">待付款</div>
                            <div style={{color: "red", fontSize: "12px"}}>剩余支付时间
                                <SecondsDown
                                    initialSeconds={detail.created_at + 1800 - parseInt(Number(new Date() / 1000))}></SecondsDown>
                            </div>
                        </div>

                        <div>需付款: {detail.amount}元
                            <Button style={{
                                marginLeft: "20px",
                                width: '80px',
                                height: '32px',
                                border: "1px solid #C59D60",
                                borderRadius: "20px",
                                boxShadow: "none",
                                fontSize: "12px"
                            }}
                                    onClick={() => cancelOrder(detail)}>取消预约</Button>
                            <Button style={{
                                marginLeft: "10px",
                                width: '80px',
                                height: '32px',
                                border: "none",
                                backgroundColor: "#C59D60",
                                borderRadius: "20px",
                                color: "#FFFFFF",
                                boxShadow: "none",
                                fontSize: "12px"
                            }}
                                    onClick={() => handleOpen(detail)}>立即支付</Button>
                        </div>
                    </div>
                }

                {//待确认预约
                    detail.sub_status == OrderEnum.orderToConfirm && <div className="between-slider">
                        <div>
                            <div className="detail-status">预约确认中</div>
                            <span className="order-status-desc">预约成功后，我们将向您预留的手机号发送短信通知，请注意查收</span>
                        </div>
                        <div>
                            <Button style={{
                                marginLeft: "20px",
                                width: '80px',
                                height: '32px',
                                border: "1px solid #C59D60",
                                borderRadius: "20px",
                                boxShadow: "none",
                                fontSize: "12px",
                                color: "#C59D60"
                            }}
                                    onClick={() => cancelOrder(detail)}>取消预约</Button>
                        </div>
                    </div>
                }

                {//已预约
                    detail.sub_status == OrderEnum.orderIsConfirmed && <div className="between-slider">
                        <div>
                            <div className="detail-status">预约成功</div>
                            <span className="order-status-desc">您已成功预约餐厅,请按照日期时间前往就餐</span>
                        </div>
                        <div>
                            <Button style={{
                                marginLeft: "20px",
                                width: '80px',
                                height: '32px',
                                border: "1px solid rgb(153, 153, 153)",
                                borderRadius: "20px",
                                boxShadow: "none",
                                fontSize: "12px",
                                color: "rgb(153, 153, 153)"

                            }}
                                    onClick={() =>refundOrderBefore(detail.id)}>申请退款</Button>
                            <Button style={{
                                marginLeft: "10px",
                                width: '80px',
                                height: '32px',
                                border: "1px solid #C59D60",
                                borderRadius: "20px",
                                boxShadow: "none",
                                fontSize: "12px",
                                color: "#C59D60"
                            }}
                                    onClick={() =>orderFinishModelOpen(detail.id)}>就餐完成</Button>
                        </div>

                    </div>
                }
                {//已预约
                    detail.sub_status == OrderEnum.orderMealDone && <div className="between-slider">
                        <div>
                            <div className="detail-status">就餐完成</div>
                            <span className="order-status-desc">您还未申请退套餐押金</span>
                        </div>
                        <div>
                            <Button style={{
                                marginLeft: "20px",
                                width: '100px',
                                height: '32px',
                                border: "1px solid #C59D60",
                                borderRadius: "20px",
                                boxShadow: "none",
                                fontSize: "12px",
                                color: "#C59D60"

                            }}
                                    onClick={() =>orderApplyRefundDepositModelOpen(detail.id)}>申请退押金</Button>

                        </div>

                    </div>
                }
                {//待退款
                    detail.sub_status == OrderEnum.orderToRefund && <div className="between-slider">
                        <div>
                            <div className="detail-status">待退款</div>
                        </div>
                    </div>
                }

                {//已取消
                    detail.sub_status == OrderEnum.orderCanceled && <div className="between-slider">
                        <div>
                            <div className="detail-status">已取消</div>
                        </div>
                    </div>
                }

                {//已关闭
                    detail.sub_status == OrderEnum.orderClose && <div className="between-slider">
                        <div>
                            <div className="detail-status">已关闭</div>
                        </div>
                    </div>
                }
                {//已关闭
                    detail.sub_status == OrderEnum.orderFinish && <div className="between-slider">
                        <div>
                            <div className="detail-status">已完成</div>
                            <span className="order-status-desc">套餐押金已退回</span>
                        </div>
                    </div>
                }
                {//申请中
                    detail.sub_status == OrderEnum.orderRefundDepositReceipt && <div className="between-slider">
                        <div>
                            <div className="detail-status">申请中</div>
                            <span className="order-status-desc">申请通过后套餐押金会原路返回</span>
                        </div>
                    </div>
                }

                <div className="order-detail-store-name">{detail?.store?.name} <span
                    className={"title-space"}>预约详情</span></div>
                <div className="list-content-item-box">
                    <div className="order-content-item-head  detail-header">
                        预约详情
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>预约号:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(ご予約番号)</div></div>
                        {detail.sn}
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>预约日期:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(日時)</div></div>
                        <div className="detail-item-right">
                            {detail.sub_date && detail.sub_date.join('  ' + detail.sub_time + '，')} {detail.sub_time}
                        </div>


                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>预约人数:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(人数)</div></div>
                        {detail.sub_num}
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>预约者:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(お客様名)</div></div>
                        {detail.name}
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>联系电话:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(連絡先)</div></div>
                        {detail.mobile}
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left"><div style={{lineHeight:"20px",marginTop:"5px",}}>备注栏:</div><div style={{lineHeight:"20px",color:"#999999",fontSize:"12px"}}>(特記事項)</div></div>
                        <div className="detail-item-right">
                            {detail.remark?detail.remark:"无"}
                        </div>
                    </div>
                </div>

                <div className="list-content-item-box ">
                    <div className="order-content-item-head  detail-header">
                        预约套餐
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left">套餐名称:</div>
                        {detail?.set_meal?.name}
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left">套餐费用:</div>
                        {detail?.set_meal?.price}元
                    </div>
                </div>
                <div className="commit-notice" style={{fontSize:"14px"}}>
                    *套餐费用需在线下餐厅支付。
                </div>
                <div className="list-content-item-box ">
                    <div className="order-content-item-head  detail-header">
                        金额明细
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left">预约费用:</div>
                        {(detail.amount - detail?.deposit_receipt).toFixed(2) + "元 "} ({(detail.amount-detail?.deposit_receipt).toFixed(2) / detail.sub_num}元x{detail.sub_num})
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left">套餐押金:</div>
                        {detail?.deposit_receipt}元
                    </div>
                    <div className="detail-item grey-font">
                        <div className="detail-item-left">总计:</div>
                        {detail.amount + "元"}
                    </div>
                </div>
                <div>
                    <div className="detail-sub-price-desc-title">
                        金额说明
                    </div>
                    <div className="grey-font" style={{fontSize: "13px"}}>
                        {detail?.store?.sub_price_desc}
                    </div>

                </div>
                <div className="list-content-item-box">
                    <div className="order-content-item-head  detail-header">
                        取消费率说明
                    </div>
                    {detail?.store?.cancel_rule && detail?.store?.cancel_rule.map((item) => {
                        return (
                            <>
                                <div className="detail-item grey-font">
                                    <div className="detail-item-left">{item[0]}天内取消:</div>
                                    {item[1]}%
                                </div>
                            </>)
                    })}
                </div>
            </div>}

            <Modal
                title="立即支付"
                open={visible}
                onCancel={handleClose}
                footer={null}
                centered
                width={460}
                style={{borderRadius: "4px"}}
            >
                <div className="text-center">
                    {paySchema &&
                        <div>
                            <div className="pay-icon-box">
                                <div className={payChooseWx ? "pay-btn-box choose" : "pay-btn-box"} onClick={() => {
                                    targetPayChooseWx(true)
                                }}><img className="pay-icon" src={wxIcon} alt=""/>微信支付
                                </div>
                                <div className={!payChooseWx ? "pay-btn-box choose" : "pay-btn-box"} onClick={() => {
                                    targetPayChooseWx(false)
                                }}><img className="pay-icon" src={aliIcon} alt=""/>支付宝支付
                                </div>
                            </div>
                            {payChooseWx && <img
                                width="220px" style={{marginTop: "10px"}}
                                src={baseUrl + 'tool/qrcode?size=220&content=' + paySchema}
                            />}
                            {!payChooseWx && <iframe
                                width="220px" height="220px"
                                style={{marginTop: "10px", border: "none", scrolling: "no"}}
                                src={paySchema[0] === "w" ? "" : paySchema}
                            />}
                        </div>}
                    {/*{visible && detail.sn && <OrderPayStatus initialOrder = {detail} visible={visible} ></OrderPayStatus>}*/}
                </div>
            </Modal>

            <Modal
                title="取消预约"
                open={isCancelModalOpen}
                onOk={confirmCancel} onCancel={cancelCancel}
                placement="bottom"
                centered
                width={400}
                style={{borderRadius: "4px"}}
                footer={[
                    // <Button key="back" width={120} style={{
                    //     width: "120px",
                    //     height: "40px",
                    //     marginRight: "31px",
                    //     marginBottom: "25px",
                    //     fontSize: "14px",
                    //     borderRadius:"4px",
                    //     // }} onClick={cancelRefundBefore}>取消</Button>,
                    // }} onClick={cancelCancel}>取消</Button>,
                    <Button key="submit" width={120}
                            style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                        // type="primary" onClick={confirmRefundBefore}>确认退款</Button>,
                            type="primary" onClick={iKnow}>我知道了</Button>,
                ]}
            >
                <div className="text-center">
                    {/*您确定取消预约吗*/}
                    "取消预约"请联系客服
                </div>
            </Modal>
            <Modal
                title="取消预约"
                open={isCancelModalOpenBeforePay}
                onOk={confirmCancelBeforePay} onCancel={cancelCancelBeforePay}
                placement="bottom"
                centered
                width={400}
                style={{borderRadius: "4px"}}
                footer={[
                    <Button key="back" width={120} style={{
                        width: "120px",
                        height: "40px",
                        marginRight: "31px",
                        marginBottom: "25px",
                        fontSize: "14px",
                        borderRadius:"4px",
                        // }} onClick={cancelRefundBefore}>取消</Button>,
                    }} onClick={cancelCancelBeforePay}>取消</Button>,
                    <Button key="submit" width={120}
                            style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                            type="primary" onClick={confirmCancelBeforePay}>确定</Button>,
                ]}
            >
                <div className="text-center">
                    您确定取消预约吗
                </div>
            </Modal>
            <Modal
                title="就餐完成"
                open={isOrderFinishModalOpen}
                onOk={confirmFinish} onCancel={cancelFinish}
                placement="bottom"
                centered
                width={400}
                style={{borderRadius: "4px"}}
                footer={[
                    <Button key="back" width={120} style={{
                        width: "120px",
                        height: "40px",
                        marginRight: "31px",
                        marginBottom: "25px",
                        fontSize: "14px",
                        borderRadius:"4px",
                        // }} onClick={cancelRefundBefore}>取消</Button>,
                    }} onClick={cancelFinish}>取消</Button>,
                    <Button key="submit" width={120}
                            style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                        // type="primary" onClick={confirmRefundBefore}>确认退款</Button>,
                            type="primary" onClick={confirmFinish}>确定</Button>,
                ]}
            >
                <div className="text-center">
                    您确定就餐已完成吗
                </div>
            </Modal>
            <Modal
                title="申请退款"
                // open={isRefundModalOpen}
                open={isRefundBeforeModalOpen}
                onOk={confirmRefundBefore} onCancel={cancelRefundBefore}
                // onOk={confirmRefund} onCancel={cancelRefund}
                placement="bottom"
                centered
                width={540}
                style={{borderRadius: "4px"}}
                footer={[
                    <Button key="back" width={120} style={{
                        width: "120px",
                        height: "40px",
                        marginRight: "31px",
                        marginBottom: "25px",
                        fontSize: "14px",
                        borderRadius:"4px",
                        // }} onClick={cancelRefundBefore}>取消</Button>,
                    }} onClick={cancelRefundBefore}>取消</Button>,
                    <Button key="submit" width={120}
                            style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                        // type="primary" onClick={confirmRefundBefore}>确认退款</Button>,
                            type="primary" onClick={confirmRefundBefore}>确定</Button>,
                ]}
            >
                <div className="" style={{margin: "16px",marginBottom:"0px"}}>
                    <div style={{color: "#333333", fontSize: "16px"}}>
                        退款原因
                    </div>
                    <Select
                        style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}
                        onChange={handleSelectRefundReason}
                        options={refundOptions()}
                        // value={refundReason}
                        placeholder="请选择退款原因"
                    />
                </div>
            </Modal>
            <Modal
                title="退款提示"
                // open={isRefundBeforeModalOpen}
                open={isRefundModalOpen}
                // onOk={confirmRefundBefore} onCancel={cancelRefundBefore}
                onOk={confirmRefund} onCancel={cancelRefund}
                placement="bottom"
                centered
                width={540}
                style={{borderRadius: "4px"}}
                footer={[
                    <Button key="back" width={120} style={{
                        width: "120px",
                        height: "40px",
                        marginRight: "31px",
                        marginBottom: "25px",
                        fontSize: "14px",
                        borderRadius:"4px",
                    // }} onClick={cancelRefundBefore}>取消</Button>,
                    }} onClick={cancelRefund}>取消</Button>,
                    <Button key="submit" width={120}
                            style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                            // type="primary" onClick={confirmRefundBefore}>确认退款</Button>,
                            type="primary" onClick={confirmRefund}>确认退款</Button>,
                ]}
            >
                <div className="" style={{textAlign: "center"}}>
                    取消预约，可能需要扣除一定的费用
                </div>
                <div className="" style={{textAlign: "center"}}>
                    您确认取消吗
                </div>

            </Modal>
            <Modal
                title="申请退押金"
                open={isOrderApplyRefundDepositModelOpen}
                onOk={confirmRefundDeposit} onCancel={cancelRefundDeposit}
                placement="bottom"
                centered
                width={540}
                style={{borderRadius: "4px"}}
                footer={[
                    // <Button key="back" width={120} style={{
                    //     width: "120px",
                    //     height: "40px",
                    //     marginRight: "31px",
                    //     marginBottom: "25px",
                    //     fontSize: "14px",
                    //     borderRadius:"4px",
                    //     // }} onClick={cancelRefundBefore}>取消</Button>,
                    // }} onClick={cancelRefundDeposit}>取消</Button>,
                    // <Button key="submit" width={120}
                    //         style={{width: "120px", height: "40px", marginBottom: "25px", fontSize: "14px",borderRadius:"4px",}}
                    //     // type="primary" onClick={confirmRefundBefore}>确认退款</Button>,
                    //         type="primary" onClick={confirmRefundDeposit}>确定</Button>,
                ]}
            >
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={confirmRefundDeposit} onFinishFailed={cancelRefundDeposit}>
                    {/*<Form.Item*/}
                    {/*    name="info"*/}
                    {/*    label="用餐凭证"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*    style={{height:"20px;"}}*/}
                    {/*>*/}
                    {/*    /!*<input type="text" value={imgUrl}/>*!/*/}
                    {/*</Form.Item>*/}
                    <div style={{marginLeft:"20px",marginBottom:"5px"}}><span style={{color:"red"}}>*</span>用餐凭证</div>
                    <div style={{marginLeft:"20px",marginRight:"20px"}}><AliyunOSSUploadImg imgUrl={imgUrl} onChange={imgUploadResult} type="info" /></div>
                    <div style={{textAlign:"center"}}>
                        <Button style={{
                            width: "120px",
                            height: "40px",
                            marginRight: "31px",
                            marginTop: "25px",
                            fontSize: "14px",
                            borderRadius:"4px",
                            // }} onClick={cancelRefundBefore}>取消</Button>,
                        }} onClick={cancelRefundDeposit} >取消</Button>
                        <Button
                            type="primary" htmlType="submit"
                            style={{width: "120px", height: "40px",
                                marginTop: "25px",
                                fontSize: "14px",borderRadius:"4px",}} >确定</Button>
                    </div>
                </Form>

            </Modal>

        </>)
}