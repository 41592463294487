import React, {useState, useEffect} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Form, Input, Radio, message, Upload, Modal, Row, Col,ConfigProvider} from 'antd';
import defaultHeadImg from '@/assets/default.png'
import {getUser, request, setUser} from "@/utils";
import {useLocation} from "react-router-dom";
import "./index.less";
import AliyunOSSUpload from "@/components/AliyunOssComponent";
import AliyunOSSUploadImg from "@/components/AliyunOssComponent/index2";
import defaultAvatar from '@/assets/default.png'
import Config from "@/config/config";
import {Func} from "@/utils/func";
export default function UserInfoComponent() {

    const [countdown, setCountdown] = useState(60); // 初始倒计时值
    const [isCounting, setIsCounting] = useState(false); // 标志是否在倒计时中
    const [form] = Form.useForm(); // 创建表单实例
    const [info, setInfo] = useState({})
    const [isPwdModalOpen, setIsPwdModalOpen] = useState(false)

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        const response = await request.post('auth/reset-pwd', {
            mobile:info.mobile,
            password:Func.pwdEncode(values.password),
            password_again:Func.pwdEncode(values.password),
            code:values.mobileCode,
            source:1});
        if (response.ret.code){

            message.success(response.ret.cn)
        }else{
            message.success('密码修改成功')
            return setIsPwdModalOpen(false)
        }
    };
    const startCountdown = () => {
        setIsCounting(true);
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <=1) {
                    clearInterval(interval);
                    setIsCounting(false);
                    setCountdown(60)
                }
                return prevCount - 1;
            });
        }, 1000);
    };

    const handleSendCode = () => {
        try {
            console.log(form);
            let values = form.getFieldsValue();
            console.log(values);
            const phoneNumber = values.mobile;
            console.log(phoneNumber);
            // 手机号码验证，你可以根据实际需求修改验证规则
            if (/^1[3-9]\d{9}$/.test(phoneNumber)) {
                // 模拟发送验证码的异步操作，你需要替换成真实的 API 调用
                // 这里使用 setTimeout 模拟异步操作
                message.success('验证码已发送');
                startCountdown();
            } else {
                message.error('请输入有效的手机号码');
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };


    const cancelModifyPwd = () => {
        setIsPwdModalOpen(false)
    }

    const confirmModifyPwd = async () => {

        // setIsPwdModalOpen(false)
    }

    const handleNickname = (e) => {

        if (e.target.value.length > 15) {
            e.target.value = e.target.value.slice(0, 15); // 截断超出长度的值
        }
        info.nickname = e.target.value
        setInfo({...info})
    }
    const handleModifyNickname = () => {

        // console.log(info.nickname)
        modifyNickname().then(r => {
            // window.location.reload()
        });


    }

    const modifyNickname = async () => {
        try {
            const response = await request.post('auth/modify-info', {nickname: info.nickname});
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                message.success('昵称修改成功',0.5,()=>{window.location.reload()})
                let user =getUser()
                user.nickname= info.nickname
                setUser(user)
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    const modifyAvatar = async (avatar='') => {
        try {
            const response = await request.post('auth/modify-info', {avatar: avatar?avatar:info.avatar});
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                message.success('头像修改成功',0.5,()=>{window.location.reload()})
                let user =getUser()
                user.avatar= info.avatar
                setUser(user)
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    const modalStyles = {
        header: {
            borderRadius:8,
            paddingInlineStart: 5,
        },
        body: {
            boxShadow: 'inset 0 0 5px #999',
            borderRadius: 5,
        },
        content:{
            borderRadius:8,
        },
        footer: {
            borderTop: '1px solid #333',
        },

    };
    const imgUploadResult=(v)=>{
        if(v[v.length-1]['status']==="done"){
            const imgUrl = v[v.length-1]['url'];
            const ossPrefix = Config.imgPrefix;
            modifyAvatar(imgUrl)
            info.avatar=imgUrl
            setInfo({...info});
            console.log(v);
        }
    }

    const fetchData = async (page, perPage) => {
        try {
            const response = await request.get('auth/info'); // 使用封装好的Axios实例发送GET请求
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                setInfo(response.data)
                setUser(response.data)
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {

        fetchData();
    }, [])


    return (
        <div className="userinfo-from">
            <div className="userinfo-title">
                个人信息
            </div>
            <div className="userinfo-img">
                <img style={{borderRadius:"50%"}} src={info.avatar?Func.imgUrlFormat(info.avatar):defaultAvatar} width="100%" height="100%" alt=""/>
            </div>
            {/*<Upload showUploadList={false} {...props}>*/}
            {/*    <Button icon={<UploadOutlined/>} style={{marginLeft:"20px"}}>上传图片</Button>*/}
            {/*</Upload>*/}
            <div  style={{marginLeft:"20px"}}><AliyunOSSUpload onChange={imgUploadResult} type="avatar" /></div>
            <div className="userinfo-item-box">
                <div className="userinfo-label">
                    昵称:
                </div>
                <div>
                    <Input placeholder="请输入您的昵称" onChange={handleNickname} value={info.nickname} style={{width: '322px'}}/>
                    <Button onClick={handleModifyNickname} style={{marginLeft:"20px"}}>修改昵称</Button>
                </div>
                {info.mobile!=="" && <div>
                    <div  className="userinfo-label">手机号码:</div>

                    <Input placeholder=""  value={info.mobile} disabled style={{width: '322px'}}/>
                    <div  className="userinfo-label">密码:</div>
                    <div>
                        <Input placeholder="********" disabled style={{width: '322px'}}/><Button
                        onClick={() => setIsPwdModalOpen(true)} style={{marginLeft:"20px"}}>修改密码</Button>
                    </div>
                </div>}


            </div>

            <ConfigProvider
                modal={{
                    styles: modalStyles,
                }}
            >
            <Modal
                className="userinfo-modify-pwd-model"
                title="修改密码"
                open={isPwdModalOpen}
                onOk={confirmModifyPwd} onCancel={cancelModifyPwd}
                placement="bottom"
                centered
                width={460}
                styles={modalStyles}
                footer=""
            >
                <div className="text-center">

                        <div className="forgetPassword-container">

                            <Form
                                layout='vertical'
                                initialValues={{
                                    checked: false,
                                }}
                                onFinish={onFinish}
                                validateTrigger={['onBlur']}
                                form={form}
                            >

                                <Form.Item
                                    label="手机号码"
                                >
                                    <Form.Item
                                        label="手机号码"
                                        noStyle
                                        name="mobile"
                                        initialValue={info.mobile}
                                    >

                                        <Input  size="large"    disabled/>

                                    </Form.Item>
                                </Form.Item>

                                <Form.Item
                                    label="手机验证码"
                                >

                                    <Row gutter={20}>
                                        <Col span={16}>
                                            <Form.Item
                                                name="mobileCode"
                                                noStyle
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入手机验证码',
                                                    },
                                                ]}
                                                autoComplete="off"
                                            >
                                                <Input placeholder="请输入手机验证码" size="large" autoComplete="off"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Button
                                                className='sender-code'
                                                type="primary"
                                                size="large"
                                                onClick={handleSendCode}
                                                disabled={isCounting}
                                                style={{
                                                    backgroundColor: isCounting ? '#f9f5ef' : '',
                                                    color: isCounting ? '#C59D60' : '',
                                                    marginLeft:0,
                                                    borderRadius:"4px",
                                                }}
                                            > {isCounting ? `${countdown}S后重发` : '发送验证码'}</Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="新的密码">
                                    <Form.Item
                                        name="password"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入密码',
                                            },
                                        ]} type="password">
                                        <Input.Password placeholder="请输入密码" size="large"/>
                                    </Form.Item>
                                </Form.Item>
                                <div className='modify-pwd-hint'>
                                    密码8-18位，大/小写字母、数字和特殊字符中至少3种
                                </div>
                                <div>
                                    <Button onClick={cancelModifyPwd}
                                        style={{
                                        width: "120px",
                                        height: "40px",
                                        marginRight: "31px",
                                        marginBottom: "25px",
                                        fontSize: "14px",
                                        borderRadius: "4px",
                                    }}>取消</Button>
                                    <Button
                                        type="primary" htmlType="submit"
                                        style={{
                                        width: "120px",
                                        height: "40px",
                                        marginBottom: "25px",
                                        fontSize: "14px",
                                        borderRadius: "4px",
                                    }}>确定</Button>
                                </div>
                            </Form>
                        </div>

                </div>
            </Modal>
            </ConfigProvider>
        </div>
    )
}
