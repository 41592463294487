import React, { useState, useEffect } from 'react'
import {Pagination, Carousel, Breadcrumb} from 'antd';
import { useNavigate } from 'react-router-dom';
import {request} from "@/utils/request"
import './index.less'
import Detail from "@/pages/Detail";
import NavigationBar from "@/components/NavigationBar";
import Config from "@/config/config";
import {RightOutlined} from "@ant-design/icons";
import noSearch from '@/assets/noSearch.png'
export default function Search() {
    const [list, setList] = useState([])
    const [queryName, setQueryName] = useState('')
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(12)
    const [total, setTotal] = useState(0)
    const queryParams = new URLSearchParams(window.location.search);

    const [selectedItem, setSelectedItem] = useState(null);

    const showDetails = (item) => {
        setSelectedItem(item);
    };
    const fetchData = async (page,perPage,name='',sect_id='',city_id='') => {
        try {
            let url ='store/list'+`?page=${page}&per-page=${perPage}`;
            if(name){
                url+="&name="+name
            }
            if(sect_id){
                url+="&sect_id="+sect_id
            }
            if(city_id){
                url+="&city_id="+city_id
            }
            const response = await request.get(url);
            let data=response.data
            setList(data)
            setTotal(response._meta.totalCount)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }



    };

    useEffect(() => {

        fetchData(page,perPage,queryParams.get('name'));

    }, [])

    const navigate = useNavigate()

    const doSearch=(val)=>{
        console.log(val)
        setQueryName(val)
        navigate('/search?name='+val)
        fetchData(0,perPage,val);
        setPage(0)
        // fetchData(0,perPage,val)
    }


    const pageChange = (e, v) => {
        console.log(e, v);
        setPage(e)
        setPerPage(v)
        fetchData(e,v,queryParams.get('name'));
    }
    const toNotice = (item) => {

        if (item.typ==="2"){
            navigate('/detail?id='+item.bind_id)
        }else{
            navigate('/noticeParticulars?id='+item.id)
        }
        console.log(item);

    }


    return (
        <div className='main-content'>
            <NavigationBar showNavRight={true} showNavTabs={true} showSearch={true} onSearchChange={doSearch}></NavigationBar>

            <div className="heart">
                <div className="notice-title" style={{marginTop:"8px"}}>
                    <Breadcrumb separator="" >
                        {sessionStorage.getItem("mbx")===null&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                        {sessionStorage.getItem("mbx")==='main'&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                        {sessionStorage.getItem("mbx")==='canteen'&&   <Breadcrumb.Item href="/canteen">餐厅</Breadcrumb.Item>}
                        <Breadcrumb.Separator><RightOutlined /></Breadcrumb.Separator>
                        <Breadcrumb.Item >搜索结果</Breadcrumb.Item>

                    </Breadcrumb>
                </div>
                <div style={{marginTop:"32px",fontSize:"20px"}}>搜索结果</div>
                <div className='main-item' style={{marginTop:"15px"}}>
                    {list.map((item, idx) => {
                        return (
                            <div key={item.id}>
                                <div key={idx} className={'box '+ "item-position-"+idx%4}>
                                    <div className="picture"  onClick={() => navigate('/detail?id='+item.id)}>{item.prize_enable == 1 && <div className="prize-icon">抽选</div>}<img src={Config.imgPrefix+item.cover} style={{borderRadius:"4px"}} width="100%" height="100%" alt=""/></div>
                                    <div className="name">{item.name}</div>
                                    <div className="city" style={{fontSize:"12px"}}>{item.sect && item.sect.val}/{item.city && item.city.val}</div>
                                </div>
                            </div>
                        )
                    })}
                    {list.length===0&&
                        <>
                            <div width={1920} height={600} style={{margin:"0 auto",padding:"160px"}}>
                                <img width={280} src={noSearch} alt=""/>
                                <div width={280} style={{textAlign:"center"}}><span style={{color:"#999999",fontSize:"14px"}}>没有搜索结果</span></div>
                            </div>

                        </>}
                    {selectedItem && <Detail item={selectedItem} />}
                </div>
                {list.length>0&&<Pagination
                    className='main-pagination'
                    total={total}
                    pageSize={perPage}
                    pageSizeOptions={[12, 24, 36, 48]}
                    onChange={pageChange}
                    showQuickJumper
                    showTotal={(total) => `共${total}条`}
                    current={page}
                />}
            </div>

        </div>
    )
}