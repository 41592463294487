import React, {useState, useEffect,} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

// import 'moment/dist/locale/zh-cn';
// import locale from 'antd/es/date-picker/locale/zh_CN';
//
import zhCN from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

import {Breadcrumb, DatePicker, Space, Select, Button,message} from 'antd';
import moment from 'moment';
import {request} from "@/utils";
import "./index.less";
// import MultipleDatePicker from './';

export default function SubOrderForm() {

    const navigate = useNavigate();
    const location = useLocation();
    const [detail, setDetail] = useState({});
    const searchParams = new URLSearchParams(location.search);

    const timeOptions = function () {

        let arr = [];

        for (let i=0;i<detail.sub_time.length;i++){
            arr.push({
                value:detail.sub_time[i],
                label: detail.sub_time[i],
            })
        }
        if(arr.length){
            return arr;
        }
        let h = '';
        for (let i = 0; i < 24; i++) {
            if (i < 10) {
                h = "0" + i;
            } else {
                h = i
            }
            arr.push({
                value: h + ':00',
                label: h + ':00',
            })
            arr.push({
                value: h + ':30',
                label: h + ':30',
            })
        }

        return arr;
    }


    const numsOptions = function (num = 6) {
        let arr = [];
        for (let i = 1; i <= num; i++) {
            arr.push({
                value: i,
                label: i+'人',
            })

        }

        return arr;
    }

    const packageOptions = function (subPackageList) {
        let arr = [];
        for (let i = 0; i < subPackageList.length; i++) {
            subPackageList[i].val=i;
            arr.push({
                value: JSON.stringify(subPackageList[i]),
                label:
                    <div className="package-box">
                    <div>{subPackageList[i].name.length>20?(subPackageList[i].name.slice(0,20)+"..."):subPackageList[i].name}</div>
                    <div><span style={{fontSize:"12px",color:"#999999"}}>套餐费用：{subPackageList[i].price}元</span></div>
                    <div><span style={{fontSize:"12px",color:"#999999"}}>押金</span><span style={{color:"#EA0025"}}> ¥ {subPackageList[i].deposit_receipt}</span></div>
                </div>,
            })

        }

        return arr;
    }

    const fetchData = async () => {
        try {
            const response = await request.get('store/detail?id=' + searchParams.get("id"));
            setDetail(response.data || {})
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    // const onChange = (date, dateString) => {
    //   console.log(date, dateString);
    // };


    // const onChange = (date, dateString) => {
    //   if (!dateString || dateString.length === 0) {
    //     // 清空已选择的日期
    //     setDates([]);
    //   } else {
    //     dates.push(date)
    //     // 添加新的日期到已选择的日期列表
    //     setDates([...dates]);
    //   }
    //   let formatData=[];
    //   for (let i=0;i<dates.length;i++){
    //     formatData.push(moment(dates[i]).format('YYYY-MM-DD'))
    //   }
    //   sessionStorage.setItem("sub_date", formatData.join(','));
    //   console.log(formatData);
    // };
    const getPopupContainer = () => {
        return document.getElementById('datePicker-container');
    };



    const MultipleDatePicker = () => {
        // 选中的日期 timestamp[]
        const [refresh,setRefresh]=useState(0)
        const [selectedDate, setSelectedDate] = useState([])

        const isCanSubDate= (date) =>{
            let list =detail.sub_dates;
            if (list.length===0){
                return true;
            }

            for(let i=0;i<list.length;i++){
                if(date===list[i]){
                    return true;
                }
            }
            return false;
        }
        // 日期发生变化时 重复去重 没有则添加
        const onValueChange = (date) => {
            console.log(date);
            const newDate = moment(date).format("YYYY-MM-DD")
            if(!isCanSubDate(newDate)){
                message.error("可预约日期"+detail.sub_dates.join(','))
                return
            }
            // const newDate = moment(date).startOf("day").valueOf()
            if (selectedDate.includes(newDate)) {
                let list =[...selectedDate.filter(item => item !== newDate)];
                list.sort((a, b)=> {
                    return moment(a).unix()-moment(b).unix();
                })
                setSelectedDate(list)
                sessionStorage.setItem("sub_date", list.join(','));
            } else {
                let list =[...selectedDate, newDate];
                list.sort((a, b)=> {
                    return moment(a).unix()-moment(b).unix();
                })
                setSelectedDate(list)
                sessionStorage.setItem("sub_date", list.join(','));
            }

        };

        // 渲染选中日期外观
        const dateRender = (currentDate) => {
            // const isSelected = selectedDate.includes(moment(currentDate).startOf("day").valueOf())
            let isSelected = selectedDate.includes( moment(currentDate).format("YYYY-MM-DD"))
            // let bgStyle=null
            let selectStyle = isSelected ?
                {
                    position: 'absolute',
                    zIndex: 9999,
                    // display: 'inlineBlock',
                    width: "50px",
                    height: "40px",
                    lineHeight: "40px",
                    backgroundColor: "#C59D60",
                    color: "#fff",
                    margin: "auto",
                    top: 0, left: 0, right: 0, bottom: 0,
                    borderRadius: "8px",
                    transition: "background 0.3s, border 0.3s"
                }: {
                    position: 'absolute',
                    zIndex: 9999,
                    width: "50px",
                    height: "40px",
                    lineHeight: "40px",
                    // backgroundColor: "#CDCDCD",
                    color: "#000",
                    margin: "auto",
                    top: 0, left: 0, right: 0, bottom: 0,
                    borderRadius: "8px",
                    transition: "background 0.3s, border 0.3s"
                };
            console.log(currentDate);
            console.log(isCanSubDate(moment(currentDate).format("YYYY-MM-DD")));
            if (!isSelected && isCanSubDate(moment(currentDate).format("YYYY-MM-DD")) && moment(currentDate).format("YYYY-MM-DD")>=moment().format("YYYY-MM-DD")){
                selectStyle={
                    position: 'absolute',
                    zIndex: 9999,
                    width: "50px",
                    height: "40px",
                    lineHeight: "40px",
                    // backgroundColor: "#f7f7f7",
                    border:"1px solid #CDCDCD",
                    color: "#000",
                    margin: "auto",
                    top: 0, left: 0, right: 0, bottom: 0,
                    borderRadius: "8px",
                    transition: "background 0.3s, border 0.3s",
                    backgroundImage:"",
                    backgroundPosition:"bottom right"
                }
                // bgStyle={backgroundColor:"#fff",position:"absolute",bottom:0,right:0,width:"16px",height:"16px"}

            }


            return (<div className={moment(currentDate).format("YYYY-MM-DD")} style={selectStyle}
                         onClick={(e)=>{
                console.log(e.target)
                onValueChange(currentDate)
            }}> {currentDate.date()}
                {/*<div style={bgStyle}></div> */}
            </div>)


        }

        return (
            <>
                <div className="date-picker">
                    <DatePicker
                        locale={zhCN}
                        getPopupContainer={getPopupContainer}
                        open
                        style={{
                            width: "547px",
                            left: "32px",
                            top: "-31px",
                            border: "none",
                            boxShadow: "none",
                        }}
                        dateRender={dateRender}
                        onChange={onValueChange}
                        val={sessionStorage.getItem('sub_date')}
                        showToday={false}
                        disabledDate={disabledDate}
                    />
                    {/*<Button type='primary' onClick={() => console.log(selectedDate)}>确定</Button>*/}
                </div>
            </>
        )
    }


    const SubFrom=(props)=>{
        const [time, setTime] = useState(props.detail.sub_time[0]);
        const [num, setNum] = useState(1);
        const [subPackage, setSubPackage] = useState(null);
        const [depositReceipt, setDepositReceipt] = useState(null);
        const handleChangeSubNum = (value) => {
            setNum(value)
            sessionStorage.setItem("sub_num", value);
            console.log(`selected ${value}`);
        };
        const handleChangePackage = (value) => {
            setSubPackage(value)
            const packageJson = JSON.parse(value)
            if (packageJson !== null){
                setDepositReceipt(packageJson.deposit_receipt)
            }

            sessionStorage.setItem("sub_package", value);
        };
        const handleChangeSubTime = (value) => {
            setTime(value)
            sessionStorage.setItem("sub_time", value);
            console.log(`selected ${value}`);
        };
        const commit=() => {
            if (!sessionStorage.getItem("sub_date")){
                message.error("请选择日期")
                return;
            }
            if (sessionStorage.getItem("sub_date")){
                if (sessionStorage.getItem("sub_date").split(',').length>5){
                    message.error("最多预约5个日期")
                    return;
                }
            }
            if (!sessionStorage.getItem("sub_package") || sessionStorage.getItem("sub_package")==null){
                message.error("请选择套餐")
                return;
            }
            if (time&&num&& sessionStorage.getItem("sub_date")){
                navigate('/subOrderCommit?id=' + detail.id)
            }

        }
        useEffect(()=>{
            sessionStorage.removeItem('sub_package')
            sessionStorage.setItem('sub_time',time)
            sessionStorage.setItem('sub_num',num)
        },[])
        return (
            <>
                <div className="form-box-content">
                    {/*<div>*/}

                    {/*</div>*/}
                    {detail.sub_time&&<div className="form-box-content">
                        <div style={{fontSize:"20px"}}>预约时间 <span style={{color:"#999999"}}>（日本当地时间）</span></div>
                        <div>
                            <Select
                                defaultValue=""
                                style={{width: "100%", marginTop: "10px", marginBottom: "10px",height:"40px"}}
                                onChange={handleChangeSubTime}
                                options={timeOptions()}
                                value={time}
                                size="large"
                            />
                        </div>
                    </div>}

                    <div>
                        <div style={{fontSize:"20px"}}>
                            预约人数
                        </div>
                        <Select
                            defaultValue="0"
                            style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}
                            onChange={handleChangeSubNum}
                            options={numsOptions()}
                            value={num}
                            size="large"
                        />
                    </div>
                    <div>
                        <div style={{fontSize:"20px"}}>
                            预约套餐
                        </div>
                        <Select
                            defaultValue="0"
                            placeholder="请选择套餐"
                            style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}
                            onChange={handleChangePackage}
                            options={packageOptions(detail.set_meal??[])}
                            value={subPackage}
                            size="large"

                        />
                    </div>

                    <div className="sub-order-form-fee">
                        金额明细
                    </div>
                    <div className="sub-fee-calculate-item">
                        <div className="sub-fee-calculate-item-left">预约费用:</div>
                        {detail.sub_price} 元/人 x {num}
                    </div>
                    {subPackage && <div className="sub-fee-calculate-item">
                        <div className="sub-fee-calculate-item-left">套餐押金:</div>
                        {depositReceipt*num} 元
                    </div>}
                    <div className="sub-fee-calculate-item">
                        <div className="sub-fee-calculate-item-left">总计:</div>
                        {((detail.sub_price?detail.sub_price:0) * num + depositReceipt*num).toFixed(2)} 元
                    </div>


                    <div>
                        <div className="grey-paragraph">
                            {detail.sub_price_desc}
                        </div>
                    </div>
                    <div>

                        <Button type="primary" style={{borderRadius: "4px",height:"48px"}} onClick={commit} block>
                            确定预约
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const disabledDate = (current) => {
        // 禁用不在 allowedDates 列表中的日期
        const allowedDates = [
            // moment('2023-12-15'),
        ];
        if (detail.sub_dates.length===0){
            return !moment(current).format("YYYY-MM-DD")>=moment(new Date()).format("YYYY-MM-DD")
        }
        for (let i=0;i<detail.sub_dates.length;i++){
            if(detail.sub_dates[i]>=moment(new Date()).format("YYYY-MM-DD")){
                allowedDates.push(moment(detail.sub_dates[i]))
            }
        }
        return !allowedDates.some((date) => moment(current).isSame(date, 'day'));
    };


    useEffect(() => {
        console.log('refresh')
        sessionStorage.setItem('sub_date','')
        sessionStorage.setItem('sub_time','')
        sessionStorage.setItem('sub_num','')
        fetchData();
    }, []);


    return (
        <>
            <div className="sub-order-content-box" style={{marginTop:"8px"}}>
                <Breadcrumb separator=">" style={{height: "32px", lineHeight: "32px", fontSize: "12px"}}>
                    {sessionStorage.getItem("mbx")===null&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                    {sessionStorage.getItem("mbx")==='main'&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                    {sessionStorage.getItem("mbx")==='canteen'&&   <Breadcrumb.Item href="/canteen">餐厅</Breadcrumb.Item>}
                    {/*<Breadcrumb.Item>*/}
                    {/*    <a href="/">主页</a>*/}
                    {/*</Breadcrumb.Item>*/}
                    <Breadcrumb.Item>
                        <a href={"/detail?id="+detail.id}>餐厅详情</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>餐厅预约</Breadcrumb.Item>
                </Breadcrumb>
                <div className="">
                    <div className="sub-order-from-store"><span className="sub-order-from-store-name">{detail.name}</span> 预约</div>
                    <div className="sub-order-form">
                        <div className="date-box" id="datePicker-container">
                            {detail.sub_dates &&<MultipleDatePicker></MultipleDatePicker>}
                        </div>



                        <div className="form-box">
                            {detail.sub_time && <SubFrom detail={detail}></SubFrom>}
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

