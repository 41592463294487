import React, {useEffect, useState} from 'react'
import './index.less'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { useNavigate } from 'react-router-dom';
import {request, setToken,setUser} from '@/utils';
import NavigationBar from '@/components/NavigationBar'
import wxIcon from '@/assets/wx.png'
import {Func} from "@/utils/func";

export default function Login() {
  const navigate = useNavigate()
  const [wxUrl, setWxUrl] = useState(""); // 初始倒计时值
  const onFinish = (values) => {

    const postData = async () => {
      try {

        const response = await request.post('auth/login',{
          mobile:values.mobile,
          password:Func.pwdEncode(values.password),
          code:888888
        });
        if (response.ret.code){
          message.success(response.ret.cn)
        }else{
          setToken(response.data.token)
          response.data.token && message.success('登录成功')
          const responseInfo = await request.get('auth/info');
          responseInfo.data && setUser(responseInfo.data)
          navigate('/')
        }
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    postData()
  };
  const toRegister = () => {
    navigate('/register')
  }
  const forgetPassword = () => {
    console.log('忘记密码');
      navigate('/forgetPassword')
  }

  const wxlogin = ()=>{
    const wxUrlReq = async () => {
      const response = await request.get('/wxxcx/get-login-url?redirect_url='+window.location.href);
      if (!response.ret.code) {
        setWxUrl(response.data)
      }
    }
    wxUrlReq()
  }

  const setUserInfo =async ()=>{
    const responseInfo = await request.get('auth/info');
    responseInfo.data && setUser(responseInfo.data)
  }

  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (token){
      setToken(token)
      setUserInfo().then(r => {
        navigate('/')
      });

    }else{
      wxlogin();
    }
  }, [])
  return (
    <div className="login">
      <NavigationBar showNavRight={false} showNavTabs={false}></NavigationBar>

      <div className="login-container">
        <div className="right-box">
          <div className='login-header'>登录
            {/*<div className="login-tabs"></div>*/}
          </div>
          {/* 登录表单 */}
          <Form
            layout='vertical'
            initialValues={{
              remember: false,
            }}
            onFinish={onFinish}
            validateTrigger={['onBlur']} >
            <Form.Item style={{fontWeight:"bold",color:"#333333"}} label="手机号码">
              <Form.Item
                noStyle
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号码',
                  },
                  {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '手机号码格式不对'
                  }
                ]}>
                <Input placeholder="请输入手机号码" size="large" />
              </Form.Item>
            </Form.Item>

            <Form.Item style={{fontWeight:"bold",color:"#333333"}} label="密码">
              <Form.Item
                noStyle
                name="password"
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]} type="password">
                <Input.Password placeholder="请输入密码" size="large" />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                noStyle
                className='login-form-remember'>

                {/*<Checkbox className='login-checkbox-label'>记住密码</Checkbox>*/}
              </Form.Item>

              <span className="login-form-forgot" onClick={forgetPassword}>
                忘记密码？
              </span>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block className="login-form-button" size="large">
                登录
              </Button>
              <div className="login-bottom-item">
                <div className='registerAccount' onClick={toRegister}>注册新账号</div>
                <div className="registerAccount-right">其他登录方式<a href={wxUrl}><img className="login-icon" src={wxIcon}  alt=""/></a></div>
              </div>

            </Form.Item>
          </Form>
        </div>
      </div>

    </div>
  )
}
