import React, {useEffect, useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Form, message, Upload} from 'antd';
import {getUser, request, setUser} from "@/utils";
import Config from "@/config/config";


const AliyunOSSUpload = ({value, onChange,type}) => {
    const [OSSData, setOSSData] = useState();

    const getSTSData = async () => {
        try {
            const response = await request.get('tool/sts-token?type=image',);
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                let storage="";
                if(type){
                    storage=type;
                }
                const tmpObj=response.data
                const data={
                    "accessId":tmpObj.access_key_id,
                    "host": Config.imgPrefix,
                    "policy": tmpObj.policy,
                    "signature": tmpObj.signature,
                    "securityToken": tmpObj.security_token,
                    "dir": "reservation/"+storage+"/",
                };
                return data;
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }


    // Mock get OSS api
    // https://help.aliyun.com/document_detail/31988.html
    const mockGetOSSData = () => ({
        // dir: 'user-dir/',
        // expire: '1577811661',
        // host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
        // accessId: 'c2hhb2RhaG9uZw==',
        // policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
        // signature: 'ZGFob25nc2hhbw==',

    });
    const init = async () => {
        try {
            const result = await getSTSData();
            setOSSData(result);
        } catch (error) {
            message.error(error);
        }

    };
    useEffect(() => {
        init();
    }, []);
    const handleChange = ({fileList}) => {
        console.log('Aliyun OSS:', fileList);
        onChange?.([...fileList]);
    };
    const onRemove = (file) => {
        const files = (value || []).filter((v) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };
    const getExtraData = (file) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
        "x-oss-security-token":OSSData.securityToken,

    });
    const beforeUpload = async (file) => {

        if (!OSSData) return false;
        const expire = Number(OSSData.expire) * 1000;
        if (expire < Date.now()) {
            await init();
        }
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        // @ts-ignore
        file.url = OSSData.dir + filename;
        return file;
    };
    const uploadProps = {
        name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,

    };
    return (
        <Upload showUploadList={false} {...uploadProps}>
            <Button icon={<UploadOutlined/>}>上传图片</Button>
        </Upload>
    );
};
export default AliyunOSSUpload
