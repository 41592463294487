import Login from "@/pages/Login";
import Layout from "@/pages/Layout";
import NotFound from "@/pages/NotFound";
import AuthRoute from "@/components/AuthRoute";
import Register from "@/pages/Register";
import UserAgreement from "@/pages/UserAgreement";
import ForgetPassword from "@/pages/ForgetPassword";
import Main from "@/pages/Main";
import Canteen from "@/pages/Canteen";
import NoticeParticulars from "@/pages/NoticeParticulars";
import Search from "@/pages/Search";
import CanteenDetails from "@/pages/CanteenDetails";
import Detail from "@/pages/Detail";
import Info from "@/pages/Info";
import SubOrderForm from "@/pages/SubOrderForm";
import SubOrderCommit from "@/pages/SubOrderCommit";

import { createBrowserRouter } from "react-router-dom";
import {ConfigProvider} from "antd";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthRoute>
        <Layout />
      </AuthRoute>
    ),
    children: [
      {
        // path: '/main',
        index: true,
        element: <Main />,
      },
      {
        path: "/canteen",
        element: <Canteen />,
      },
      {
        path: "/noticeParticulars",
        element: <NoticeParticulars />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/canteenDetails",
        element: <CanteenDetails />,
      },
      {
        path: "/detail",
        element: <Detail />,
      },
      {
        path: "/info",
        element: <Info />,
      },
      {
        path: "/subOrderForm",
        element: <SubOrderForm />,
      },
      {
        path: "/subOrderCommit",
        element: <SubOrderCommit />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/userAgreement",
    element: <UserAgreement />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
