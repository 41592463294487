import React, { useState, useEffect } from 'react';

const SecondsDown = ({ initialSeconds }) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    const formatSeconds=(sec)=>{
        let minutes=parseInt(sec/60)
        let seconds=sec%60;
        if (seconds<10){
            seconds="0"+seconds
        }
        return minutes+":"+seconds
    }

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [seconds]);

    return (
        <span>
            {seconds > 0 ? (
                <span>{formatSeconds(seconds)}</span>
            ) : (
                <span>已超时</span>
            )}
        </span>
    );
};

export default SecondsDown;