import { Breadcrumb } from 'antd';
import React, {useEffect, useState} from 'react';
import './index.less'
import { RightOutlined } from '@ant-design/icons'
import {useLocation, useNavigate} from "react-router-dom";
import {request} from "@/utils";
import NavigationBar from "@/components/NavigationBar";
import Config from "@/config/config";

export default function NoticeParticulars() {
    const navigate = useNavigate();
    const location = useLocation();
    // const detail = location.state || {};
    const searchParams = new URLSearchParams(location.search);
    const [detail,setDetail] = useState( {});


    const onAnchorChange = (e) => {
        console.log(e);
        console.log(detail);
    };
    const fetchData = async () => {
        try {
            const response = await request.get('banner/detail?id='+searchParams.get("id"));
            setDetail(response.data ||{}　)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='notice'>
            <NavigationBar showNavRight={false} showNavTabs={false}></NavigationBar>
            <div className="notice-title" style={{marginTop:"8px"}}>
                <Breadcrumb separator="">
                    {sessionStorage.getItem("mbx")===null&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                    {sessionStorage.getItem("mbx")==='main'&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                    {sessionStorage.getItem("mbx")==='canteen'&&   <Breadcrumb.Item href="/canteen">餐厅</Breadcrumb.Item>}
                    <Breadcrumb.Separator><RightOutlined /></Breadcrumb.Separator>
                    <Breadcrumb.Item >通告详情</Breadcrumb.Item>

                </Breadcrumb>
            </div>
            <div className="notice-picture">
                <img src={Config.imgPrefix+detail.web_url} width="100%" height={320} style={{objectFit:"cover"}} alt=""/>
            </div>
            <div className="notice-content" dangerouslySetInnerHTML={{__html:detail.detail}}>

            </div>
        </div>
    )
}


