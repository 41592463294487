import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Breadcrumb, Anchor, Row, Col, Carousel, Tabs} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {baseIframeUrl, request} from "@/utils/request";
import moment from 'moment';
import "./index.less";
import Config from "@/config/config";
import GMap from "@/pages/Detail/map";

const {Link} = Anchor;

export default function Detail() {
    const navigate = useNavigate();
    const location = useLocation();
    // const detail = location.state || {};
    const searchParams = new URLSearchParams(location.search);
    const queryParams = new URLSearchParams(window.location.search);
    const [detail, setDetail] = useState({});
    const [imgs, setImgs] = useState([]);
    const [canSub, setCanSub] = useState(true);

    const mapUrl = () => {
        let url = baseIframeUrl+"/resource/map.html?"
        return url + "lat=" + detail.lat + "&lng=" + detail.lng + "&title=" + detail.name + "&address=" + detail.address_detail;
    }

    const onAnchorChange = (e) => {
        console.log(e);
        console.log(detail);
    };
    const fetchData = async () => {
        try {
            const response = await request.get('store/detail?id=' + searchParams.get("id") + "&platform=web");
            setDetail(response.data || {});
            setImgs(response.data.imgs)
            let date = response.data.sub_dates;
            if (date.length > 0) {
                let validate = moment(date[date.length - 1]).valueOf() > moment().valueOf()
                if (!validate) {
                    setCanSub(false)
                }
            }

            if (response.data.prize_enable===1){
                let condition1 = moment(response.data.prize_start_at*1000).valueOf() < moment().valueOf()
                let condition2 = moment(response.data.prize_end_at*1000).valueOf() > moment().valueOf()
                let validate = condition1 && condition2
                if (!validate) {
                    setCanSub(false)
                }
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    const renderTabBar=(e)=>{
        console.log(e)
        return (
            <>
            <div>123123</div>
            </>
        )
    }
    const getItems = () => {
        if(detail.prize_enable===0){
            return [
                {
                    "key": "1",
                    label: '餐厅介绍',
                },
                {
                    "key": "2",
                    label: '套餐',
                },
                {
                    "key": "3",
                    label: '预约说明',
                },
                {
                    "key": "4",
                    label: '取消费率说明',
                },
                {
                    "key": "5",
                    label: '餐厅说明',
                },
            ]
        }else{
            return [
                {
                    "key": "1",
                    label: '餐厅介绍',
                },
                {
                    "key": "2",
                    label: '套餐',
                },
                {
                    "key": "3",
                    label: '抽选活动',
                },
                {
                    "key": "4",
                    label: '预约说明',
                },
                {
                    "key": "5",
                    label: '取消费率说明',
                },
                {
                    "key": "6",
                    label: '餐厅说明',
                },
            ]
        }


    }

    const onChange = (key) => {
        console.log(key)
        document.getElementsByClassName('ant-anchor-link-title')[key-1].click();
    }

    useEffect(() => {
        window.scrollTo(0,0)
        fetchData();
    }, []);

    return (
        <div className="content-detail">
            <div className="detail-box">
                <div className="detail-title" style={{marginTop: "8px"}}>
                    <Breadcrumb separator="">
                        {sessionStorage.getItem("mbx")===null&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                        {sessionStorage.getItem("mbx")==='main'&&   <Breadcrumb.Item href="/">主页</Breadcrumb.Item>}
                        {sessionStorage.getItem("mbx")==='canteen'&&   <Breadcrumb.Item href="/canteen">餐厅</Breadcrumb.Item>}
                        <Breadcrumb.Separator>
                            <RightOutlined/>
                        </Breadcrumb.Separator>
                        <Breadcrumb.Item>餐厅详情</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="detail-container-header">
                    <div className="header-left">
                        {detail.name}
                    </div>
                    <div className="header-right">
                        <div className="right-left">预约费: {detail.sub_price}元/人</div>
                        {canSub && <div className="right-right"
                                        onClick={() => navigate('/subOrderForm?id=' + detail.id, detail)}>预约餐厅</div>}
                        {!canSub && <div className="right-right-cannot">暂时无法预约</div>}
                    </div>
                </div>
                <div
                    className="header-text">{detail.sect && detail.sect.val + '/'}{detail.city && detail.city.val}</div>
                <div className="header-img">
                    <Carousel autoplay>
                        {imgs.length && imgs.map((item) => {
                            return (
                                (<div className="header-img">
                                    <img src={Config.imgPrefix + item} width="100%" height="100%"
                                         style={{objectFit: "cover"}} alt=""/>
                                </div>)
                            )
                        })}
                    </Carousel>

                </div>

                <div className="divider"></div>
                <div className="content-box" style={{boxShadow: "none",paddingTop:"0"}}
                >
                    <div
                        className="content-tabs"
                        style={{
                            paddingLeft: "20px",
                            display:"none"
                        }}
                    >
                        <Anchor
                            affix={false}
                            direction="horizontal"
                            onChange={onAnchorChange}
                            offsetTop={120}
                        >
                            <Link
                                href="#desc"
                                title="餐厅介绍"
                            />
                            <Link
                                href="#package_desc"
                                title="套餐"
                            />
                            {detail.prize_enable === 1 &&
                                <Link
                                    href="#prize"
                                    title="抽选活动"
                                />
                            }
                            <Link
                                href="#sub_desc"
                                title="预约说明"
                            />
                            <Link
                                href="#cancel_rule"
                                title="取消费率说明"
                            />
                            <Link
                                href="#rest_desc"
                                title="餐厅说明"
                            />
                        </Anchor>

                    </div>
                    <div className="detail-tabs">
                        <Tabs defaultActiveKey="1" items={getItems()} indicatorSize={200} onChange={onChange} size="large"
                            // renderTabBar={renderTabBar}
                              tabBarStyle={{
                                  width:"1200px",
                                  fontSize:"20px",
                                  borderBottom:"2px solid rgba(0,0,0,0.098)"
                              }}
                              tabBarGutter={60}
                        />
                    </div>
                    <div>
                        <div id="desc" className="canting">
                            <div className="canting-title">餐厅介绍</div>
                            <div className="canting-content">
                                {detail.desc}
                            </div>
                        </div>
                        <div id="package_desc" className="canting">
                            <div className="canting-title">套餐</div>
                            <div className="canting-content">
                                <div dangerouslySetInnerHTML={{__html: detail.package_desc}}/>
                            </div>
                        </div>
                        {detail.prize_enable === 1 &&
                            <div id="prize" className="canting" >
                                <div className="canting-title" >抽选活动
                                </div>
                                <div className="canting-content">
                                    {detail.prize_enable === 1}
                                    <div>
                                        <div >
                                            <div style={{color:"#000"}}>活动时间: <span style={{color: "#666666"}}>{moment(detail.prize_end_at * 1000).unix()<moment(new Date()).unix()?"已结束":"" }</span></div>
                                       <div style={{color: "#666666"}}>
                                                {moment(detail.prize_start_at * 1000).format("YYYY年MM月DD日")}-{moment(detail.prize_end_at * 1000).format("YYYY年MM月DD日")}
                                            </div>
                                            <div>
                                            <span
                                                style={{color: "#666666"}}>中奖人在{moment(detail.sub_dates[0]).format("YYYY年MM月DD日")} {detail.sub_time[0]}可前往餐厅就餐</span>
                                            </div>
                                        </div>
                                        <div style={{marginTop: "14px",color:"#000"}}>
                                            活动说明:
                                            <div style={{color: "#666666"}}>
                                                {detail.prize_rule}
                                            </div>
                                        </div>
                                        {
                                            (detail.prize_member.length>0) &&
                                            <>
                                                <div style={{marginTop: "30px",color:"#000"}}>
                                                    中奖名单:
                                                </div>
                                                <div className="prize-member-content">
                                                    {detail.prize_member.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="prize-member-item">
                                                                    <div className="prize-member-item-name"
                                                                         style={{borderTop: "none"}}>{item.name}</div>
                                                                    <div className="prize-member-item-mobile">{item.mobile}</div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }



                                    </div>
                                </div>
                            </div>
                        }
                        <div id="sub_desc" className="canting">
                            <div className="canting-title">预约说明</div>
                            <div className="canting-content">
                                <div dangerouslySetInnerHTML={{__html: detail.sub_desc}}/>
                            </div>
                        </div>
                        <div id="cancel_rule" className="canting">
                            <div className="canting-title">取消费率说明</div>
                            <div className="canting-content">
                                {detail.cancel_rule && detail.cancel_rule.map((item) => {
                                    return (
                                        <>
                                            <div className="cancel-rule-desc-item">
                                                <div className="cancel-rule-item-label">{item[0]}天内取消， 扣{item[1]}%</div>

                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="rest_desc" className="canting">
                            <div className="canting-title">餐厅说明</div>
                            <div className="canting-content">
                                <div dangerouslySetInnerHTML={{__html: detail.rest_desc}}/>
                            </div>
                        </div>
                        <div>
                            {detail && <iframe scrolling="no" src={mapUrl()} width={572}
                                               height={338} frameBorder="0"></iframe>}
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}
