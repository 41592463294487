import React, { useState } from 'react'
import './index.less'
import { Form, Input, Button, Checkbox, message, Row, Col, } from 'antd'
import { useNavigate } from 'react-router-dom';
import NavigationBar from '@/components/NavigationBar'
import {request} from "@/utils";
import {Func} from "@/utils/func";
import {EnumAgreement} from "@/utils/enum";
export default function Register() {
    const navigate = useNavigate()
    const [countdown, setCountdown] = useState(60); // 初始倒计时值
    const [isCounting, setIsCounting] = useState(false); // 标志是否在倒计时中
    const [form] = Form.useForm(); // 创建表单实例
    const startCountdown = () => {
        setIsCounting(true);
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(interval);
                    setIsCounting(false);
                    setCountdown(60)
                }
                return prevCount - 1;
            });
        }, 1000);
    };
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const postData = async () => {
            try {
                const response = await request.post('auth/register',{
                    mobile:values.mobile,
                    password:Func.pwdEncode(values.password),
                    code:values.mobileCode,
                    source:2,
                });
                if (response.ret.code){
                    message.success(response.ret.cn)
                }else{
                    message.success('注册成功')
                    navigate('/login')
                }
                console.log(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        postData()


    };
    const serviceAgreement = (e) => {
        e.preventDefault()
        // console.log('服务协议');
        // navigate('/userAgreement')
        window.open('/userAgreement?typ='+EnumAgreement.fuwu, '_blank')

    };
    const privacyPolicy = (e) => {
        e.preventDefault()
        // console.log('隐私政策');
        window.open('/userAgreement?typ='+EnumAgreement.yinsi, '_blank')
    };
    const handleSendCode = () => {
        try {
            console.log(form);
            let values = form.getFieldsValue();
            console.log(values);
            const phoneNumber = values.mobile;
            console.log(phoneNumber);
            // 手机号码验证，你可以根据实际需求修改验证规则
            if (/^1[3-9]\d{9}$/.test(phoneNumber)) {
                const response =  request.post('auth/mobile-code',{
                    mobile:values.mobile,
                    type:1
                });
                // 这里使用 setTimeout 模拟异步操作
                message.success('验证码已发送');
                startCountdown();
            } else {
                message.error('请输入有效的手机号码');
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };
    const toLogin = () => {
        navigate('/login')
    }
    return (
        <div className="register">
            <NavigationBar showNavRight={false} showNavTabs={false}></NavigationBar>
            <div className="register-container">
                <div className='register-header'>注册
                </div>
                <Form
                    layout='vertical'
                    initialValues={{
                        checked: false,
                    }}
                    onFinish={onFinish}
                    validateTrigger={['onBlur']}
                    form={form}
                >
                    <Form.Item style={{fontWeight:400,color:"#333333",fontSize:"16px"}} label="手机号码" >
                        <Form.Item
                            noStyle
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号码',
                                },
                                {
                                    pattern: /^1[3-9]\d{9}$/,
                                    message: '手机号码格式不对'
                                }
                            ]}
                        >
                            <Input style={{fontWeight:400,color:"#333333",fontSize:"16px"}} placeholder="请输入手机号码" size="large" value='' />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item style={{fontWeight:400,color:"#333333",fontSize:"16px"}}
                        label="手机验证码"
                    >

                        <Row gutter={20}>
                            <Col span={16}>
                                <Form.Item
                                    name="mobileCode"
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入手机验证码',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入手机验证码" size="large" value='' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Button
                                    className='sender-code'
                                    type="primary"
                                    size="large"
                                    onClick={handleSendCode}
                                    disabled={isCounting}
                                    style={{ backgroundColor: isCounting ? '#f9f5ef' : '', color: isCounting ? '#C59D60' : '' }}
                                > {isCounting ? `${countdown}S后重发` : '发送验证码'}</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label="密码" style={{fontWeight:400,color:"#333333",fontSize:"16px"}}>
                        <Form.Item
                            name="password"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码',
                                },
                            ]} type="password">
                            <Input.Password placeholder="请输入密码" size="large"  value='Cjh123456!' />
                        </Form.Item>
                    </Form.Item>


                    <Form.Item
                        name="checked"
                        valuePropName="checked"
                        className='register-form-remember'
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('请查看并同意相关协议')),
                            },
                        ]}>

                        <Checkbox className='register-checkbox-label'>我已阅读并同意<span className='register-agreement' onClick={serviceAgreement}>《服务协议》</span> 和<span className='register-agreement' onClick={privacyPolicy}>《隐私政策》</span></Checkbox>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit" block className="register-form-button" size="large">
                            注册
                        </Button>
                        <div className='to-login' onClick={toLogin}>已有账号，去登录</div>
                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}
