import React, { useState } from 'react'
import './index.less'
import { Form, Input, Button, message, Row, Col, } from 'antd'
import NavigationBar from '@/components/NavigationBar'
import {request} from "@/utils";
import {Func} from "@/utils/func";
import {useNavigate} from "react-router-dom";
export default function ForgetPassword() {
    const navigate=useNavigate()
    const [countdown, setCountdown] = useState(60); // 初始倒计时值
    const [isCounting, setIsCounting] = useState(false); // 标志是否在倒计时中
    const [form] = Form.useForm(); // 创建表单实例
    const startCountdown = () => {
        setIsCounting(true);
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(interval);
                    setIsCounting(false);
                    setCountdown(60)
                }
                return prevCount - 1;
            });
        }, 1000);
    };
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const postData = async () => {
            try {
                const response = await request.post('auth/reset-pwd',{
                    mobile:values.mobile,
                    password:Func.pwdEncode(values.password),
                    password_again:Func.pwdEncode(values.password),
                    code:values.mobileCode,
                    source:1
                });
                if (response.ret.code){
                    message.success(response.ret.cn)
                }else{
                    message.success('密码修改成功')
                    navigate('/login')
                }
                console.log(response.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        postData()
    };
    const handleSendCode = () => {
        try {
            console.log(form);
            let values = form.getFieldsValue();
            console.log(values);
            const phoneNumber = values.mobile;
            console.log(phoneNumber);
            // 手机号码验证，你可以根据实际需求修改验证规则
            if (/^1[3-9]\d{9}$/.test(phoneNumber)) {
                const response =  request.post('auth/mobile-code',{
                    mobile:values.mobile,
                    type:2
                });
                // 这里使用 setTimeout 模拟异步操作
                message.success('验证码已发送');
                startCountdown();
            } else {
                message.error('请输入有效的手机号码');
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    return (
        <div className="forgetPassword">
            <NavigationBar showNavRight={false} showNavTabs={false}></NavigationBar>
            <div className="forgetPassword-container">
                <div className='forgetPassword-header'>
                    忘记密码
                </div>
                <Form
                    layout='vertical'
                    initialValues={{
                        checked: false,
                    }}
                    onFinish={onFinish}
                    validateTrigger={['onBlur']}
                    form={form}
                >
                    <Form.Item label="手机号码" >
                        <Form.Item
                            noStyle
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号码',
                                },
                                {
                                    pattern: /^1[3-9]\d{9}$/,
                                    message: '手机号码格式不对'
                                }
                            ]}
                        >
                            <Input placeholder="请输入手机号码" size="large" />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        label="手机验证码"
                    >

                        <Row gutter={20}>
                            <Col span={16}>
                                <Form.Item
                                    name="mobileCode"
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入手机验证码',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入手机验证码" size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Button
                                    className='sender-code'
                                    type="primary"
                                    size="large"
                                    onClick={handleSendCode}
                                    disabled={isCounting}
                                    style={{ backgroundColor: isCounting ? '#f9f5ef' : '', color: isCounting ? '#C59D60' : '' }}
                                > {isCounting ? `${countdown}S后重发` : '发送验证码'}</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label="密码">
                        <Form.Item
                            name="password"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码',
                                },
                            ]} type="password">
                            <Input.Password placeholder="请输入密码" size="large" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <div className='forgetPassword-hint' >
                            新密码由8-18位大/小写英文字母和数字组成
                        </div>
                        <Button type="primary" htmlType="submit" block className="forgetPassword-form-button" size="large">
                            确定
                        </Button>

                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}
