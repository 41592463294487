import React, {useEffect,useState} from 'react'
import './index.less'

import NavigationBar from '@/components/NavigationBar'
import {request} from "@/utils";
import {useLocation} from "react-router-dom";




export default function UserAgreement() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [data,setData]= useState({})

    const fetchData = async () => {
        try {
            const response = await request.get('agreement/detail?sub_type='+searchParams.get('typ'));
            setData(response.data ||{}　)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='userAgreement'>
            <NavigationBar showNavRight={false} showNavTabs={false}></NavigationBar>
            <div className="userAgreement-container">
                <div className="userAgreement-title">
                    {data.sub_type ===1 && "使用条款" }
                    {data.sub_type ===2 && "隐私协议" }
                    {data.sub_type ===3 && "预约协议" }
                    {data.sub_type ===4 && "关于我们" }
                    {data.sub_type ===5 && "服务协议" }
                </div>
                <div className="userAgreement-content" dangerouslySetInnerHTML={{__html:data.content}}>

                </div>
            </div>
        </div>
    )
}