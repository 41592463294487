import {OrderEnum} from "@/utils/enum";
import CryptoJs from "crypto-js";
import Config from "@/config/config";

const Func={
    imgUrlFormat:(url="")=>{
        let validateUrl = (url.indexOf("http")===-1)
        if(validateUrl){
            return Config.imgPrefix+url;
        }else{
            return url;
        }
    },
    pwdEncode:(pwd='')=>{

        const iv = CryptoJs.enc.Utf8.parse(Config.pwd.iv)
        const key = CryptoJs.enc.Utf8.parse(Config.pwd.key)
        pwd = CryptoJs.enc.Utf8.parse(pwd)

        const enCode = CryptoJs.AES.encrypt(pwd, key, {iv:iv,mode:CryptoJs.mode.CBC,padding:CryptoJs.pad.Pkcs7});

        return enCode.toString()
    },
    getCancelRuleList:(res=[],key="key")=>{
        try{
            if(res){
                res.sort(function (x,y){
                    return -(x[0]-y[0]);//升序
                })
            }
            return  res?res:[];
        }catch ($e){

        }finally {

        }

        return [];

    },
    isOrderActionShow:(item,actionName)=>{

        if(actionName==='pay'){
            if(item.sub_status===OrderEnum.orderToPaid){
                return true;
            }
            return false;

        }
        if(actionName==='apply-refund'){
            if(item.sub_status===OrderEnum.orderIsConfirmed){
                return true;
            }
            return false;

        }

        if(actionName==='cancel'){
            if(item.sub_status===OrderEnum.orderToPaid){
                return true;
            }

            if(item.sub_status===OrderEnum.orderToRefund){
                return false;
            }

            if(item.sub_status===OrderEnum.orderCanceled){
                return false;
            }
            if(item.sub_status===OrderEnum.orderToConfirm){
                return true;
            }
            if(item.sub_status===OrderEnum.orderIsConfirmed){
                return false;
            }
            if(item.sub_status===OrderEnum.orderFinish){
                return false;
            }
            if(item.sub_status===OrderEnum.orderClose){
                return false;
            }
            if(item.sub_status===OrderEnum.orderMealDone){
                return false;
            }
            if(item.sub_status===OrderEnum.orderRefundDepositReceipt){
                return false;
            }
        }

        if(actionName==="apply-refund-deposit"){
            if(item.sub_status===OrderEnum.orderMealDone){
                return true;
            }
            return false;
        }

        return true;
    }
}

export { Func }