import {Layout, Input, Dropdown, Space, Popover, Form, Modal, Button, Checkbox, message} from 'antd';
import React, {useState, useEffect} from 'react';
import {SearchOutlined, DownOutlined, DeleteOutlined} from '@ant-design/icons';
import './index.less'
import {Link, useLocation, useNavigate} from "react-router-dom";

// 图片
import service from '@/assets/service.png'
import logo from '@/assets/logo.png'
import consult from '@/assets/consult.png'
import defaulthead from '@/assets/default.png'
import orderForm from '@/assets/order-form.png'
import personal from '@/assets/personal.png'
import outLogin from '@/assets/outLogin.png'
import {getToken, request, setToken, getUser} from "@/utils";
import Config from "@/config/config";
import {Func} from "@/utils/func";
import TextArea from "antd/es/input/TextArea";

const {Header} = Layout;

const WxService = () => {
    const [wxService, setWxService] = useState(); // 初始选中的标签

    const getWxService = async () => {
        setWxService(Config.imgPrefix + (await request.get('customer-service/detail')).data.content)
    }


    useEffect(() => {
        getWxService()
    }, [])

    return (
        <div className='nav-servicer'>
            <div className="qrcode"><img width="100%" src={wxService}></img></div>
            <div className="wxhint">请识别二维码，添加微信客服咨询</div>
        </div>)
}

const NavigationBar = ({showNavRight = true, showNavTabs = true, showSearch = false, onSearchChange}) => {

    const [form] = Form.useForm();

    const [searchValue, setSearchVal] = useState(""); // 初始选中的标签
    const [searchHistory, setSearchHistory] = useState([]); // 初始选中的标签
    const [historyShow, setHistoryShow] = useState(false); // 初始选中的标签
    const [selectedTab, setSelectedTab] = useState(); // 初始选中的标签
    const [isShopConsultModalShow, setIsShopConsultModalShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()

    const AdvanceOrder = () => {
        navigate('/info?menu=order', {state: "order"})
        console.log('预约订单');
    }
    const personalDetails = () => {
        navigate('/info?menu=user', {state: "user"})
        console.log('个人信息');
    }
    const logOut = () => {
        console.log('退出登录');
        setToken('')
        sessionStorage.setItem('user', null)
        navigate('/login')
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let sh = getSearchHistory();
            let repeat = false;
            for (let i = 0; i < sh.length; i++) {
                if (sh[i] === e.target.value) {
                    repeat = true
                }
            }
            if (!repeat) {
                sh.unshift(e.target.value)
                // sh.push(e.target.value)
                saveSearchHistory(sh)
            }

        }
        onSearchChange(e.target.value);
    }
    const handleHistorySearch = (value) => {
        onSearchChange(value);
        //设置输入框值
        setSearchVal(value)
        // setHistoryShow(false)
    }

    const resetSearchHistory = () => {
        setSearchHistory([])
        localStorage.setItem('searchHistory', JSON.stringify([]))
    }
    const getSearchHistory = () => {
        let arr = JSON.parse(localStorage.getItem('searchHistory'));
        if (arr == null) {
            arr = []
        }
        setSearchHistory(arr)
        return arr
    }
    const saveSearchHistory = (arr = []) => {
        localStorage.setItem('searchHistory', JSON.stringify(arr))
        setSearchHistory(arr)
    }
    // const searchHistoryShow=()=>{
    //     // 监听整个页面的点击事件
    //     document.addEventListener('click', searchHistoryHide);
    //
    //     setHistoryShow(true);
    // }
    // const searchHistoryHide=(e)=>{
    //     if (e.y>50){
    //         console.log(e);
    //         setTimeout(()=>{
    //             setHistoryShow(false);
    //             document.removeEventListener('click',searchHistoryHide)
    //         },200)
    //     }
    //
    // }

    const onFinish = (values) => {
        console.log('Success:', values);
        submitShopConsultCommit(values)

        // postData(data)
        //清空表单
        form.resetFields();
    };
    const onFinishFailed = (errorInfo,e2) => {
        console.log('Failed:', errorInfo);
        // navigate(-2)
        // cancelShopConsultModal()
        //清空表单
        // form.resetFields();
    };
    const onFinishCancel = (errorInfo) => {
        console.log('Cancel:', errorInfo);
        // navigate(-2)
        cancelShopConsultModal()

    };


    const SearchHistoryComp = () => {
        return (
            <>
                <div className="search-history-box">
                    <div className="search-history-box-item-head">
                        <div className="search-history-box-item-head-left">历史记录</div>
                        <div className="search-history-box-item-head-right" onClick={resetSearchHistory}>
                            <DeleteOutlined/></div>
                    </div>
                    <div className="search-history-box-content">
                        {searchHistory.map((item) => {
                            return (
                                <div className="search-history-list-item" onClick={() => {
                                    handleHistorySearch(item)
                                }}>
                                    <div className="search-history-list-item-icon">
                                        <SearchOutlined/>
                                    </div>
                                    <span className="search-history-item-text">
                                    {item}
                                </span>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </>
        )
    }

    const items = [
        {
            key: '1',
            label: (
                <div className='dropdown-item' onClick={AdvanceOrder}>
                    <span className='img'><img src={orderForm} alt=""/></span>
                    <span className='name'>预约订单</span>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className='dropdown-item' onClick={personalDetails}>
                    <span className='img'><img src={personal} alt=""/></span>
                    <span className='name'>个人信息</span>
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div className='dropdown-item' onClick={logOut}>
                    <span className='img'><img src={outLogin} alt=""/></span>
                    <span className='name'>{getToken() ? "退出登录" : "登录"}</span>
                </div>
            ),
        },

    ];

    const showShopConsultModal = () => {
        //未登录跳转到登录页
        if (!getUser()){
            navigate("/login")
            return
        }
        setIsShopConsultModalShow(true);
    }
    const cancelShopConsultModal = () => {
        //清空表单
        form.resetFields();
        setIsShopConsultModalShow(false);
    }
    const submitShopConsultCommit = (val) => {
        console.log(val);

        //提交数据
        //提交退款申请
        const consult = async () => {
            const response = await request.post('consult/info' , {
                "mobile" : val.mobile,
                "name" : val.name,
                "content" : val.content
            }); // 使用封装好的Axios实例发送GET请求

            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                message.success('咨询成功');
            }
        }
        consult();
        cancelShopConsultModal()
    }

    // 在组件加载时设置初始选中标签
    useEffect(() => {
        getSearchHistory()
        const pathname = location.pathname;
        if (pathname === '/' || pathname === '/noticeParticulars' || pathname === '/search') {
            setSelectedTab('首页');
        } else if (pathname === '/canteen') {
            setSelectedTab('餐厅');
        }
    }, [location.pathname]);
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <Layout>
            <Header className='nav-header'>
                <div className="nav-heart">
                    <div className="nav-left">
                        <div className="logo"> <img width="100%" src={logo} alt=""/></div>

                        {showNavTabs && (
                            <div className="nav-tabs">
                                <Link to="/" onClick={() => handleTabClick('首页')}>
                                    <span className={`router-main ${selectedTab === '首页' ? 'selected' : ''}`}>
                                        首页
                                    </span>
                                </Link>
                                <Link to="/canteen" onClick={() => handleTabClick('餐厅')}>
                                    <span className={`router-canteen ${selectedTab === '餐厅' ? 'selected' : ''}`}
                                    >
                                        餐厅
                                    </span>
                                </Link>

                            </div>
                        )}

                    </div>
                    {showNavRight && (
                        <div className="nav-right">
                            {showSearch &&
                                <Popover placement="bottom" content={SearchHistoryComp} title=""
                                         style={{borderRadius: "8px"}}>
                                    <Input
                                        className='search-input'
                                        onPressEnter={handleSearch}
                                        // onClick={searchHistoryShow}
                                        // defaultValue={searchValue}
                                        // value={searchValue}
                                        maxLength={99}
                                        size="large"
                                        placeholder="搜索餐厅"
                                        prefix={<SearchOutlined style={{color: '#999999'}}/>}/>
                                </Popover>
                            }
                            <div className="consult">
                                <img src={consult} onClick={showShopConsultModal} alt=""/>
                            </div>
                            <div className="service">
                                <Popover placement="bottom" content={WxService} title="微信客服"
                                         style={{borderRadius: "8px"}}>
                                    <img src={service} alt=""/>
                                </Popover>
                            </div>
                            <div className="user">

                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottomRight"
                                    arrow

                                >
                                    <span>
                                        <Space>
                                            <div className='user-item'>
                                                <div className="head-portrait">
                                                    <img className="avatar"
                                                         src={getUser()?.avatar ? (Func.imgUrlFormat(getUser()?.avatar)) : defaulthead}
                                                         width="100%" height="100%" alt=""/>
                                                </div>
                                                <div className="username">{getUser()?.nickname}</div>
                                            </div>
                                            <DownOutlined style={{color: '#999999', fontSize: '12px'}}/>
                                        </Space>
                                    </span>
                                </Dropdown>
                            </div>
                        </div>
                    )}

                </div>
            </Header>
            {historyShow && <SearchHistoryComp></SearchHistoryComp>}

            <Modal
                title="餐厅咨询"
                open={isShopConsultModalShow}
                onOk={submitShopConsultCommit} onCancel={cancelShopConsultModal}
                placement="bottom"
                centered
                width={460}
                style={{borderRadius: "4px"}}
                footer={[]}
                // footer={[
                //     <Button key="back" width={120} style={{
                //         width: "120px",
                //         height: "40px",
                //         marginRight: "31px",
                //         marginBottom: "25px",
                //         fontSize: "14px",
                //         borderRadius: "4px",
                //         // }} onClick={cancelRefundBefore}>取消</Button>,
                //     }} onClick={cancelShopConsultModal}>取消</Button>,
                //     <Button key="submit" width={120}
                //             style={{
                //                 width: "120px",
                //                 height: "40px",
                //                 marginBottom: "25px",
                //                 fontSize: "14px",
                //                 borderRadius: "4px",
                //             }}
                //             type="primary" onClick={submitShopConsultCommit}>确定</Button>,
                // ]}
            >
                <div className="text-center">
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        initialValues={{remember: false}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        // style={{marginTop: "40px"}}
                    >
                        <Form.Item
                            label="姓名"
                            name="name"
                            style={{
                                width: "500px"
                            }}
                            rules={[{required: true, message: '请输入您的姓名!'}]}
                        >
                            <Input placeholder="请输入您的姓名" maxLength={5}/>
                        </Form.Item>
                        <Form.Item
                            label="联系方式"
                            name="mobile"
                            style={{
                                width: "500px"
                            }}
                            rules={[{
                                required: true,
                                message: '请输入手机号或微信号',
                                // pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g")
                            }]}
                        >
                            <Input placeholder="请输入手机号或微信号"/>
                        </Form.Item>
                        <Form.Item
                            label="留言"
                            name="content"
                            style={{
                                width: "500px"
                            }}
                            rules={[{required: true, message: '请输入留言信息'}]}
                        >
                            <TextArea placeholder="留言"
                                      style={{
                                          width: "1200px",
                                          height: "120px",
                                          lineHeight: "14px",
                                          paddingTop: "12px"
                                      }}/>
                        </Form.Item>

                        <Form.Item
                            label=""
                            name=""
                            style={{
                                width: "500px",
                                marginBottom:0,
                                height:"50px",
                            }}
                        >
                            <Button key="back" width={120} htmlType="cancel" style={{
                                width: "120px",
                                height: "40px",
                                marginRight: "31px",
                                marginBottom: "25px",
                                fontSize: "14px",
                                borderRadius: "4px",
                                }} onClick={onFinishCancel}>取消</Button>
                             {/*}} >取消</Button>*/}
                            <Button  key="submit" width={120} htmlType="submit"
                                    style={{
                                        width: "120px",
                                        height: "40px",
                                        marginBottom: "25px",
                                        fontSize: "14px",
                                        borderRadius: "4px",
                                    }}
                                     type="primary" >确定</Button>
                        </Form.Item>

                    </Form>

                </div>
            </Modal>
        </Layout>
    )
};
export default NavigationBar;