import { getToken } from "@/utils";
import {Navigate, useLocation} from "react-router-dom";

const AuthRoute = ({ children }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const location = useLocation();
    const isToken = getToken()
    if (isToken) {
        return <>{children}</>
    } else {

        console.log(location.pathname)
        if (location.pathname==="/info"){
            return <Navigate to='/login' replace></Navigate>
        }
        if (location.pathname==="/subOrderForm"){
            return <Navigate to='/login' replace></Navigate>
        }
        if (location.pathname==="/subOrderCommit"){
            return <Navigate to='/login' replace></Navigate>
        }
        // return <Navigate to='/login' replace></Navigate>
    }
    return <>{children}</>
}

export default AuthRoute