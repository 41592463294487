const OrderEnum={
    orderToPaid:1,
    orderToConfirm:2,
    orderIsConfirmed:3,
    orderToRefund:4,
    orderCanceled:5,
    orderMealDone:6,
    orderRefundDepositReceipt:7,
    orderFinish:8,
    orderClose:9,
}
const EnumAgreement={
    shiyong:1,
    yinsi:2,
    yuyue:3,
    guanyu:4,
    fuwu:5,
}

export { OrderEnum,EnumAgreement }