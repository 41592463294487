
import { Breadcrumb } from 'antd';
import React from 'react';
import './index.less'
import { RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export default function CanteenDetails() {
    return (
        <div className='canteenDetails'>
            <div className="canteenDetails-nav" style={{marginTop:"8px"}}>
                <Breadcrumb separator="">
                    <Breadcrumb.Item href="/">主页</Breadcrumb.Item>
                    <Breadcrumb.Separator><RightOutlined /></Breadcrumb.Separator>
                    <Breadcrumb.Item >餐厅详情</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="canteenDetails-heart">
                <div className="canteenDetails-title">
                    <div className="canteenDetails-left">
                        <div className="name">熊の焼鳥106</div>
                        <div className="city">焼鳥/大阪</div>
                    </div>
                    <div className="canteenDetails-right">
                        <span className="cost">预约费：390日元/人</span>
                        <Button className='canteenDetails-btn' type="primary" size="large" >
                            预约餐厅
                        </Button>
                    </div>
                </div>
                <div className="canteenDetails-swiper"></div>
            </div>


        </div>
    )
}


