import React, { useState, useEffect } from 'react'
import { Pagination,Carousel  } from 'antd';
import { useNavigate } from 'react-router-dom';
import {request} from "@/utils/request"
import './index.less'
import Detail from "@/pages/Detail";
import NavigationBar from "@/components/NavigationBar";
import Config from "@/config/config";


export default function Main() {
    const [recommend, setRecommend] = useState([])
    const [banner, setBanner] = useState([])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(12)
    const [total, setTotal] = useState(0)

    const [selectedItem, setSelectedItem] = useState(null);

    const showDetails = (item) => {
        setSelectedItem(item);
    };
    const fetchData = async (page,perPage,name='',sect_id='',city_id='') => {
        try {
            let url ='store/recommend-list'+`?page=${page}&per-page=${perPage}`;
            if(name){
                url+="&name="+name
            }
            if(sect_id){
                url+="&sect_id="+sect_id
            }
            if(city_id){
                url+="&city_id="+city_id
            }
            const response = await request.get(url);
            let data=response.data
            setRecommend(data)
            setTotal(response._meta.totalCount)
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }

        const fetchBanner = async () => {
            const response = await request.get('banner/list');
            setBanner(response.data ||{}　)
        };
        fetchBanner()

    };

    useEffect(() => {
        sessionStorage.setItem("mbx","main")
        fetchData(page,perPage);


    }, [])

    const navigate = useNavigate()

    const doSearch=(val)=>{
        console.log(val)
        // fetchData(0,perPage,val)
        navigate('/search?name='+val)

    }


    const pageChange = (e, v) => {
        console.log(e, v);
        setPage(e)
        setPerPage(v)
        fetchData(e,v);
    }
    const toNotice = (item) => {

        if (item.typ==="2"){
            navigate('/detail?id='+item.bind_id)
        }else{
            navigate('/noticeParticulars?id='+item.id)
        }
        console.log(item);

    }
    const calculateTextAligin=(idx)=>{
        let res = idx%4;
        if(res===0){
            return "left"
        }
        if(res>=1&&res<=2){
            return "center"
        }
        if(res===3){
            return "right"
        }
    }

    return (
        <div className='main-content'>
            <NavigationBar showNavRight={true} showNavTabs={true} showSearch={true} onSearchChange={doSearch}></NavigationBar>
            <div className="swiper" >
                <Carousel dots={{className:"dots-style"}}
                          autoplay
                >
                    {banner&& banner.map((item)=>{
                        return (<>
                            <div onClick={()=>{toNotice(item)}}>
                                <img width="100%" height={320} src={Config.imgPrefix+item.web_url} style={{objectFit:"cover"}}/>
                            </div>
                        </>)
                    })}
                </Carousel>
            </div>
            <div className="heart">
                <div className="main-title">推荐餐厅</div>

                <div className='main-item'>
                    {recommend.map((item, idx) => {
                        return (
                            <div key={item.id} >
                                <div key={idx} className={'box '+ "item-position-"+idx%4}>
                                    <div className="picture" style={{textAlign:calculateTextAligin(idx)}} onClick={() => navigate('/detail?id='+item.id)}>{item.prize_enable == 1 && <div className="prize-icon">抽选</div>}<img src={Config.imgPrefix+item.cover} style={{borderRadius:"4px"}} width="100%" height="100%" alt=""/></div>
                                    <div className="name">{item.name}</div>
                                    <div className="city" style={{fontSize:"12px"}}>{item.sect && item.sect.val+'/'}{item.city && item.city.val}</div>
                                </div>
                            </div>
                    )
                    })}
                    {selectedItem && <Detail item={selectedItem} />}
                </div>
                <Pagination
                    className='main-pagination'
                    total={total}
                    pageSize={perPage}
                    pageSizeOptions={[12, 24, 36, 48]}
                    onChange={pageChange}
                    showQuickJumper
                    showTotal={(total) => `共${total}条`}
                    current={page}
                />
            </div>

        </div>
    )
}