import React, {useState, useEffect} from "react";
import {Col, Row} from 'antd';
import "./index.less";
import {Pagination} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {request} from "@/utils/request"
import {
    AppstoreOutlined,
    CalendarOutlined,
    LinkOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {Divider, Menu, Switch} from 'antd';
import OrderComponent from "@/components/OrderComponent";
import UserInfoComponent from "@/components/UserInfoComponent";
import NavigationBar from "@/components/NavigationBar";
import UserSvg from '@/assets/user.svg'
import UserSvgActive from '@/assets/userActive.svg'
import OrderSvg from '@/assets/order.svg'
import OrderSvgActive from '@/assets/orderActive.svg'
import {OrderEnum} from "@/utils/enum";




export default function Info(props) {



    const location = useLocation();
    const [info, setInfo] = useState('');
    const [toDetail, setToDetail] = useState(null);
    const [orderSvg, setOrderSvg] = useState(OrderSvg);
    const [userSvg, setUserSvg] = useState(UserSvg);
    const [selectItem, setSelectItem] = useState(location.state);
    const [orderType, setOrderType] = useState('');
    const searchParams = new URLSearchParams(location.search);
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate()
    const handleClick = (menu) => {

        setToDetail(null)
        setInfo(menu.key)
        setOrderType("0")
        setSelectItem(menu.key)
        if (menu.key=="order-to-paid"){
            setOrderType(OrderEnum.orderToPaid.toString())
        }
        if (menu.key=="order-to-confirm"){
            setOrderType(OrderEnum.orderToConfirm.toString())
        }
        if (menu.key=="order-is-confirmed"){
            setOrderType(OrderEnum.orderIsConfirmed.toString())
        }
        if (menu.key=="order-to-refund"){
            setOrderType(OrderEnum.orderToRefund.toString())
        }
        if (menu.key=="order-meal-done"){
            setOrderType(OrderEnum.orderMealDone.toString())
        }
        if (menu.key=="order-to-apply-deposit"){
            setOrderType(OrderEnum.orderRefundDepositReceipt.toString())
        }
        if (menu.key=="order-finish"){
            setOrderType(OrderEnum.orderFinish.toString())
        }
        if (menu.key=="user"){
            setOrderSvg(OrderSvg)
            setUserSvg(UserSvgActive)
        }else{
            setOrderSvg(OrderSvgActive)
            setUserSvg(UserSvg)
        }
        navigate("/info?menu="+menu.key)
        console.log(menu)
    }

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const items = [

        getItem('预约订单', 'sub1',   <img width="20" height="20" src={orderSvg} alt=""/>, [
            getItem('全部预约', 'order',<span>●</span>),
            getItem('待付款', 'order-to-paid',<span>●</span>),
            getItem('预约中', 'order-to-confirm',<span>●</span>),
            getItem('已预约', 'order-is-confirmed', <span>●</span>,),
            getItem('待退款', 'order-to-refund', <span>●</span>,),
            getItem('就餐完成','order-meal-done', <span>●</span>,),
            getItem('申请中', 'order-to-apply-deposit', <span>●</span>,),
            getItem('已完成', 'order-finish', <span>●</span>,),
        ]),

        getItem(
            "个人信息",
            'user',
            <img width="20" height="20" src={userSvg} alt=""/>
        ),
    ];

    const urlToDetail=()=>{
        setToDetail(searchParams.get('id'))
    }

    useEffect(()=>{
        urlToDetail();
        setSelectItem(queryParams.get('menu'))
        setInfo(queryParams.get('menu'))
        if(queryParams.get('menu')==='user'){
            setOrderSvg(OrderSvg)
            setUserSvg(UserSvgActive)
        }else{
            setOrderSvg(OrderSvgActive)
            setUserSvg(UserSvg)
        }
        // setInfo(location.state)
    },[location.state])
    return (
        <>
            <div className="userInfo-background">
                <div className="userInfo-menu">
                    {<Menu
                        style={{
                            width: 240,
                        }}
                        defaultSelectedKeys={[info==="user"?"user":"order"]}
                        selectedKeys={[selectItem]}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        theme="light"
                        items={items}
                        onClick={handleClick}
                    />}
                </div>
                <div className="userInfo-content">
                    {info === 'user' && <div> <UserInfoComponent></UserInfoComponent></div>}
                    {info !== 'user' && <div>
                        <OrderComponent orderType={orderType} toDetail={toDetail} ></OrderComponent>
                    </div>}
                </div>
            </div>


        </>
    );

}

