import NavigationBar from '@/components/NavigationBar'
import FooterBar from '@/components/FooterBar'
import './index.less'
import React, { useState, useEffect, } from 'react'
import { Outlet,useLocation } from "react-router-dom";



export default function Layout() {
    const [isGrey, setIsGrey] = useState(false);
    const location = useLocation();
    console.log(location.pathname)

    useEffect(()=>{
        if ('/info'===location.pathname){
            setIsGrey(true);
        }else{
            setIsGrey(false);
        }
    })
    return (
    <div className='home'>
        <div width="100%">
            <NavigationBar></NavigationBar>
        </div>

      <div className={"content"+(isGrey?"-grey":"")}>
        <Outlet  />
      </div>
      <FooterBar></FooterBar>
    </div>
  )
}