import React, {useState, useEffect} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Form, Input, Radio, message, Upload, Checkbox, Modal} from 'antd';
import {request} from "@/utils";
import {useLocation, useNavigate, useNavigation} from "react-router-dom";
import "./index.less";
import {Func} from "@/utils/func";
import wxIcon from "@/assets/wx.png";
import aliIcon from "@/assets/ali.png";
import {baseUrl} from "@/utils/request";

export default function SubOrderCommit() {

    const [orderId, setOrderId] = useState(null);
    const [payChooseWx, setPayChooseWx] = useState(true);
    const [visible, setVisible] = useState(false);
    const {TextArea} = Input;
    const [data, setData] = useState({})
    const [detail, setDetail] = useState({})
    const [orderDetail, setOrderDetail] = useState({})
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const fetchData = async () => {
        try {
            const response = await request.get('store/detail?id=' + searchParams.get("id"));
            setDetail(response.data || {})
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const postData = async (data) => {
        try {
            const response = await request.post('order/confirm', {
                mobile:data.mobile,
                store_id: data.store_id,
                sub_date: data.sub_date,
                sub_time: data.sub_time,
                sub_num: data.sub_num,
                buyer_openid: "",
                address: data.address,
                name: data.name,
                remark: data.remark,
                set_meal: data.set_meal,
            }); // 使用封装好的Axios实例发送GET请求
            if (response.ret.code) {
                message.error(response.ret.cn)
            } else {
                sessionStorage.setItem('orderId',response.data.id)
                getDetail(response.data.id)
                handleOpen(response.data)
                console.log(response.ret.data);
                // navigate('/info?menu=order&id='+response.data.id,{state:"order"})
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        if(!values.remember){
            message.error("请阅读并同意预约协议")
            return
        }

        data.store_id = detail.id
        data.name = values.name
        data.mobile = values.mobile
        data.remark = values.remark?values.remark:''
        data.sub_num = Number(sessionStorage.getItem('sub_num'))
        data.sub_time = sessionStorage.getItem('sub_time') ??"";
        data.sub_date = sessionStorage.getItem('sub_date').split(',')
        data.set_meal = parseInt(JSON.parse(sessionStorage.getItem("sub_package")).val)
        //提交预约
        console.log(data);
        postData(data)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // navigate(-2)
    };

    useEffect(() => {
        console.log(location);
        fetchData();
    }, [])

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const handleOpen = (item) => {
        setVisible(true);
        console.log(item);
        getPaySchema(item,payChooseWx?"wxpay":"alipay");

    }

    const targetPayChooseWx=(isWx)=>{
        setPayChooseWx(isWx)
        if(isWx){
            getPaySchema(orderDetail,"wxpay");
        }else{
            getPaySchema(orderDetail,"alipay");
        }
    }

    const getDetail = async (id)=>{
        try {
            const response = await request.get('order/detail?id=' + id); // 使用封装好的Axios实例发送GET请求
            setOrderDetail(response.data)
            if(response.data.pay_time>0){
                handleClose();
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    const [paySchema, setPaySchema] = useState('');
    const getPaySchema = async (item,type,openid="")=>{
        const response = await request.post('order/pay',{
            "sn": item.sn,
            "openid": openid,
            "platform": "web",
            "pay_type": type
        });

        if (response.ret.code){
            message.error(response.ret.cn)
        }else{
            setPaySchema(response.data);
            let count=1;

            function orderPayListen(){

                let run=sessionStorage.getItem('orderPayListen')
                if(!run ||count>600){
                    return ;
                }
                getDetail(item.id)
                console.log(run);
                count++;

                return setTimeout(orderPayListen,5000)
            }
            if(!sessionStorage.getItem("orderPayListen")){
                setTimeout(orderPayListen,5000);
                sessionStorage.setItem("orderPayListen","yes")
            }

        }
    }

    const handleClose = () => {
        setVisible(false);
        sessionStorage.setItem("orderPayListen","")
        navigate('/info?menu=order&id='+ sessionStorage.getItem('orderId'),{state:"order"})
        sessionStorage.setItem('orderId',null)
    }


    return (
        <>
            <div className="order-commit-content">
                <div>
                    <Button type="cancel" style={{with: "74px", height: "32px", marginTop: "20px",color:"#999999",borderRadius:"4px"}}
                            onClick={() => navigate(-1)}>
                        返回
                    </Button>
                </div>

                <div className="order-commit-info-content">
                    <div className="commit-head">{detail.name}</div>
                    <div className="commit-item">
                        <div className="commit-item-label">预约日期:</div>
                        {sessionStorage.getItem('sub_date')}
                    </div>
                    <div className="commit-item">
                        <div className="commit-item-label">预约时间:</div>
                        {sessionStorage.getItem('sub_time')}
                    </div>
                    <div className="commit-item">
                        <div className="commit-item-label">预约人数:</div>
                        {sessionStorage.getItem('sub_num')}人
                    </div>
                    <div className="commit-item">
                        <div className="commit-item-label">餐厅地址:</div>
                        {detail.address_detail}
                    </div>
                </div>
                <div className="commit-notice" style={{fontSize:"14px"}}>
                    *点击最终确认预约后，日期、时间、人数都将无法修改。
                </div>
                <div className="order-commit-info-content-no-shadow">
                    <div className="commit-desc-head">预约套餐</div>
                    <div className="commit-desc-item">
                        <div className="commit-item-label">套餐名称:</div>
                        {JSON.parse(sessionStorage.getItem('sub_package')).name}
                    </div>
                    <div className="commit-desc-item">
                        <div className="commit-item-label">套餐费用:</div>
                        {JSON.parse(sessionStorage.getItem('sub_package')).price}元
                    </div>
                </div>
                <div className="commit-notice" style={{fontSize:"14px"}}>
                    *套餐费用需在线下餐厅支付。
                </div>
                <div className="order-commit-info-content-no-shadow">
                    <div className="commit-desc-head">金额明细</div>
                    <div className="commit-desc-item">
                        <div className="commit-item-label">预约费用:</div>
                        {detail.sub_price*sessionStorage.getItem('sub_num')}元 ({detail.sub_price}元x{sessionStorage.getItem('sub_num')})
                    </div>
                    <div className="commit-desc-item">
                        <div className="commit-item-label">套餐押金:</div>
                        {JSON.parse(sessionStorage.getItem('sub_package')).deposit_receipt*sessionStorage.getItem('sub_num')}元
                    </div>
                    <div className="commit-desc-item">
                        <div className="commit-item-label">总计:</div>
                        {detail.sub_price*sessionStorage.getItem('sub_num') + JSON.parse(sessionStorage.getItem('sub_package')).deposit_receipt*sessionStorage.getItem('sub_num')}元
                    </div>
                </div>

                <div className="commit-sub-price-desc-content">
                    <div className="font-weight-bold">金额说明</div>
                    <div style={{color:"#666666"}}>
                        {detail.sub_price_desc}
                    </div>
                </div>


                {detail.cancel_rule &&
                    <div className="order-commit-info-content-no-shadow">
                        <div className="commit-desc-head">取消费率说明</div>
                        {detail.cancel_rule && detail.cancel_rule.map((item) => {
                            return (
                                <>
                                    <div className="commit-desc-item">
                                        <div className="commit-item-label">{item[0]}天内取消:</div>
                                        {item[1]}%
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                }
                <Form
                    name="basic"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{remember: false}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    style={{marginTop: "40px"}}
                >
                    <Form.Item
                        label="姓名"
                        name="name"
                        style={{
                            width: "500px"
                        }}
                        rules={[{required: true, message: '请输入您的姓名!'}]}
                    >
                        <Input placeholder="请输入您的姓名" maxLength={20}/>
                    </Form.Item>

                    <Form.Item
                        label="手机号码"
                        name="mobile"
                        style={{
                            width: "500px"
                        }}
                        rules={[{required: true, message: '请输入正确手机号码',pattern:new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g")}]}
                    >
                        <Input placeholder="请输入手机号码"/>
                    </Form.Item>
                    <Form.Item label="备注栏" name="remark" wrapperCol={{offset: 0, span: 24}}>
                        <TextArea placeholder="若有儿童请备注儿童人数及其他注意事项"
                                  style={{
                                      width: "1200px",
                                      height: "120px",
                                      lineHeight: "14px",
                                      paddingTop: "12px"
                                  }}/>
                    </Form.Item>


                    <div className="commit-agreement">
                        <Form.Item  name="remember" valuePropName="checked" wrapperCol={{offset: 0, span: 16} }
                                   style={{marginLeft: "20px",display:"inline-block",marginBottom:0}}>
                            <Checkbox className="commit-agreement-section" />
                        </Form.Item>
                       <div style={{marginLeft: "10px",display:"inline-block",marginBottom:0,height:"25px",lineHeight:"32px"}}>
                           <span>我已阅读并同意</span> <span
                           className="commit-agreement-font" onClick={()=>{window.open("/userAgreement?typ=3","_blank")}}>《预约协议》</span>
                       </div>
                    </div>

                    <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginTop: "16px"}}>
                        <Button type="cancel" htmlType="cancel"
                                style={{width: "120px", height: "48px", marginRight: "10px", color: "#666666",borderRadius:"4px"}}        onClick={()=>{
                            navigate(-2);
                        }} >

                            取消预约
                        </Button>
                        <Button type="primary" htmlType="submit"
                                style={{width: "120px", height: "48px", marginLeft: "10px",borderRadius:"4px"}}>
                            确认预约
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal
                title="立即支付"
                open={visible}
                onCancel={handleClose}
                footer={null}
                centered
                width={460}
                style={{borderRadius: "4px"}}
            >
                <div className="text-center">
                    {paySchema &&
                        <div>
                            <div className="pay-icon-box">
                                <div className={payChooseWx?"pay-btn-box choose":"pay-btn-box"} onClick={()=>{targetPayChooseWx(true)}} ><img className="pay-icon" src={wxIcon} alt=""/>微信支付</div>
                                <div className={!payChooseWx?"pay-btn-box choose":"pay-btn-box"} onClick={()=>{targetPayChooseWx(false)}}><img className="pay-icon" src={aliIcon} alt=""/>支付宝支付</div>
                            </div>
                            {payChooseWx&&<img
                                width="220px" style={{marginTop:"10px"}}
                                src={baseUrl + 'tool/qrcode?size=220&content=' + paySchema}
                            />}
                            {!payChooseWx && <iframe
                                width="220px" height="220px" style={{marginTop:"10px",border:"none",scrolling:"no"}}
                                src={paySchema[0]==="w"?"":paySchema}
                            />}
                        </div>}
                    {/*{visible && detail.sn && <OrderPayStatus initialOrder = {detail} visible={visible} ></OrderPayStatus>}*/}
                </div>
            </Modal>
        </>
    )
}
